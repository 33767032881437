import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from '../../css/main.css';
import headerstyles from '../../css/header.css';

import BigTitle from './BigTitle';
import CallMe from './CallMe';
import NarrowHeader from './NarrowHeader';
import MobileBurger from './MobileBurger';
import HeaderCall from '../buttons/HeaderCall';

import {router} from '../../redux/actions';

import headerLogo from '../../includes/imgs/logo.svg';

const Header = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menu = useSelector(state => state.menu.headerMenu);
  const page = useSelector(state => state.router.page);
  const onner = useSelector(state => state.controller.onner);
  const scrollnow = useSelector(state => state.controller.scroll);

    const goTo = (stringPage,evt) => {
      evt.preventDefault();
      if(stringPage !== 'main'){
        navigate("/"+stringPage)
        dispatch(router(stringPage));
      } else {
        navigate("/")
        dispatch(router(stringPage));
      }
    }

  if(window.innerWidth > 798) {
    return(
      <section className = {`${'bigHeader'}`}>

        <NarrowHeader />
        {
          (scrollnow > 300) ?
            <header className = {`${'mainHeader'}  ${ (scrollnow > 300)?'fixedHeader':'' }`}>
              <img onClick={(evt) => goTo('main',evt)} className = {`${'logo'}`} src = {`${headerLogo}`} />
              <nav className = {`${'headerNav'}`}>
                <ul>
                {
                  menu.map( (menu, index) => {
                    return <li key={index+"_HeaderNav_"+menu.title}><a className = "headerNavLinks" onClick = { evt => goTo(menu.link,evt)} href = {`/${menu.link}`}>{menu.title}</a></li>
                  })
                }
                </ul>
              </nav>

              <CallMe />
            </header>
                          : ''
        }
        <header className = {`${'mainHeader'}`}>
          <img onClick={(evt) => goTo('main',evt)} className = {`${'logo'}`} src = {`${headerLogo}`} />
          <nav className = {`${'headerNav'}`}>
            <ul>
            {
              menu.map( (menu, index) => {
                return <li key={index+"_HeaderNav_"+menu.title}><a className = "headerNavLinks" onClick = { evt => goTo(menu.link,evt)}  href = {menu.link}>{menu.title}</a></li>
              })
            }
            </ul>
          </nav>

          <CallMe />
        </header>

        {
          (page === 'main') ? //Проверяем на главной мы или нет
                            <div className = "titleWrapper">
                              <BigTitle />
                              <HeaderCall />
                            </div>
                            : false
        }

      </section>
    )
  } else {
    return(
        <>
        {
        (scrollnow > 300) ?
            <section className={`${'bigHeader'} ${(scrollnow > 300) ? 'fixedHeader' : ''}`}>

              <NarrowHeader/>

              <header className={`${'mainHeader'}`}>
                <img onClick={() => dispatch(router('main'))} className={`${'logo'}`} src={`${headerLogo}`}/>
                <MobileBurger/>
              </header>

              {
                (page === 'main') ? //Проверяем на главной мы или нет
                    <div className="titleWrapper">
                      <BigTitle/>
                      <HeaderCall/>
                    </div>
                    : false
              }

            </section>
            : ''
        }

      <section className = {`${'bigHeader'}`}>

        <NarrowHeader />

        <header className = {`${'mainHeader'}`}>
          <img onClick={() => dispatch(router('main'))} className = {`${'logo'}`} src = {`${headerLogo}`} />
          <MobileBurger />
        </header>

        {
          (page === 'main') ? //Проверяем на главной мы или нет
                            <div className = "titleWrapper">
                              <BigTitle />
                              <HeaderCall />
                            </div>
                            : false
        }

      </section>
          </>
    )

  }
}

export default Header;
