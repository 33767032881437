import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import styles from '../../css/news.css';


const PublicOffer = () => {

  const page = useSelector(state => state.router.page);

  const resultQuntity = 6;
  switch (page) {
    case 'publicoffert':
      return(
        <section className="blogContainer politContainer">
          <h2 className = "titlePage">Публичная оферта</h2>
          <div>
          <span>г. Владивосток             25.04.2022</span>

          <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>

          <p>
          1.1. Настоящее Пользовательское соглашение (далее – Соглашение) заключается между Обществом с ограниченной ответственностью «Джапан Теко» (далее – Администрация сайта) и любым лицом, предоставляющим свои персональные данные в формах регистрации и обратной связи, размещенных на сайте, расположенном в сети Интернет по адресу: www.jteco.ru (далее - «Пользователь»).
          1.2. Сайт Интернет-магазина ИП Лазович Андрей Владимирович (далее – Сайт) является собственностью ИП Лазович Андрей Владимирович
          1.3. Настоящее Соглашение регулирует отношения между Администрацией сайта Интернет-магазина ИП Лазович Андрей Владимирович (далее – Администрация сайта) и Пользователем данного Сайта.
          1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.
          1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.
          1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.
          </p>
          <h3>2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</h3>
          <p>
          2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:
          2.1.1 ИП Лазович Андрей Владимирович – Интернет-магазин, расположенный на доменном имени https://akira-auto.ru , осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.
          2.1.2. Интернет-магазин – сайт, содержащий информацию о Товарах, Продавце, позволяющий осуществить выбор, заказ и (или) приобретение Товара или услуги.
          2.1.3. Администрация сайта Интернет-магазина – уполномоченные сотрудники на управления Сайтом, действующие от имени ИП Лазович Андрей Владимирович.
          2.1.4. Пользователь сайта Интернет-магазина (далее, Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
          2.1.5. Содержание сайта Интернет-магазина (далее – Содержание) — охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, видеоматериалы, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте Интернет-магазина.
          </p>
          <h3>3. ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
          <p>
          3.1. Предметом настоящего Соглашения является предоставление Пользователю Интернет-магазина доступа к содержащимся на Сайте Товарам и оказываемым услугам.
          3.1.1. Интернет-магазин предоставляет Пользователю следующие виды услуг (сервисов):
          </p>
          <ul>
            <li>• доступ к электронному контенту на бесплатной основе, с правом (скачивания), просмотра контента;</li>
            <li>• доступ к средствам поиска и навигации Интернет-магазина;</li>
            <li>• предоставление Пользователю возможности размещения сообщений, комментариев, рецензий Пользователей, выставления оценок контенту Интернет-магазина;</li>
            <li>• доступ к информации о Товаре и к информации о приобретении Товара на бесплатной основе;</li>
            <li>• иные виды услуг (сервисов), реализуемые на страницах Интернет-магазина.</li>
            <li>• платный доступ к информации аукционов Японии и калькуляторам расчета таможенных платежей;</li>
            <li>• доставку Товара на территорию РФ на платной основе;</li>
            <li>• организацию таможенного оформления на платной основе;</li>
            <li>• отправку Товара по РФ на платной основе;</li>
          </ul>
          <p>
          3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Интернет-магазина, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-магазина.
          3.1.3 Если Пользователь оплатил услугу по поиску и подбору Товара, но Администрация сайта не удовлетворила пожелания Пользователя по поиску и подбору Товара, то Администрация сайта обязуется вернуть стоимость услуги в полном объему на банковские реквизиты Пользователя.
          3.2. Доступ к Интернет-магазину предоставляется на бесплатной основе.
          3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту, Пользователь считается присоединившимся к настоящему Соглашению.
          3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации
          </p>
          </div>
        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default PublicOffer;
