import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import LoadMore from '../buttons/loadmore';

import stylesKorea from '../../css/korea.css';
import {fetchBuyedCars} from '../../redux/actions';
import {fetchKoreaCars} from '../../redux/actions';
import thumbKorea from '../../includes/imgs/korea.png';

let stopFetchKorea = false;

const Korea = () => {

  const stopperKorea = useSelector(state => state.data.stopperKorea);
  const secretKey = useSelector(state => state.controller.secretKey);
  const linkgetcarskorea = useSelector(state => state.controller.getCarsKorea);

  const dispatch = useDispatch();

  if(!stopperKorea[0] && !stopFetchKorea) {

    dispatch(fetchKoreaCars(linkgetcarskorea,secretKey));
    stopFetchKorea = true;
  }
  return(
    <section className="koreaContainer">
      <h2 className = "titlePage">Примеры привезенных корейских авто</h2>

      <article className="koreaGallery">
        {
          (stopperKorea[0]) ?
                            stopperKorea.map((item, index) => {
                              return (
                              <div className="oneKorea">
                                <img src = {item.imglink} alt={item.name} />
                                <h4 className="nameAuto">{item.name}</h4>
                                <span className="priceAuto">{item.price} ₽ </span>
                              </div>
                              )
                            })
                            : <h3>К сожалению машин нет :( </h3>
        }
      </article>
      {
        (stopperKorea.length > 10) ?  <LoadMore /> : false
      }

    </section>
  )
}

export default Korea;
