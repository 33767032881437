import React, { useEffect , useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';


import gallery from '../../css/gallery.css';

/**
 * imgs = array
 * dir = string = 'hor' - 'vert'
*/

function Gallery({domain, subClass, imgs,dir}) {

  const [activeSlide, setActiveSlide] = useState(-1); //Активный слайд
  const [toBackData, setToBackData] = useState(-1); //Возвращаемое изображение
  const [toBig, setToBig] = useState(false); // раскрываем галлерею

  const runBigGallery = (index) => {
    setToBig(true);
  }

  const exit = () => {
    setToBig(false);
  }

  useEffect(() => {
    console.log(imgs)
  },[imgs])

  return(
    <div className={`galleryContainer ${dir} ${(toBig) ? 'bigGalleryContainer' : 'smallGalleryContainer'}`}>
      {
        (toBig) ? <div onClick={exit} className = {`exitFromGallery`}><span></span><span></span></div> : ''
      }
      {
        (typeof imgs !== 'string' && imgs[0]) ?
              <div className="galleryBlock">
                {
                  imgs.map( (img,index) => {
                    if(index < 8) {
                      return <img key = {`img_key_${img}`} className = {`${(index === activeSlide) ? 'activeImgGallery' : 'nonActiveImgGallery'} ${ (subClass) ? subClass : ''}`} onClick = {(toBig) ? () => setActiveSlide(index) : runBigGallery} key = {`img_key_${index}`} src = {`${(domain) ? domain + img : img}`} />
                    }
                  })
                }
              </div>
                  :
          <img key = {`img_key_${imgs}`} className = {`${(0 === activeSlide) ? 'activeImgGallery' : 'activeImgGallery'} ${ (subClass) ? subClass : ''}`} onClick = {(toBig) ? () => setActiveSlide(0) : runBigGallery} key = {`img_key_${0}`} src = {`${(domain) ? domain + imgs : imgs}`} />
      }
    </div>
  )
}

export default Gallery;
