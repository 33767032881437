import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchNews, newsLoader, router, setBigNews, setBodycar} from '../../redux/actions';

import stylesNews from '../../css/news.css';

const CalculatorDelivery = () => {

  const dispatch = useDispatch();
  const page = useSelector(state => state.router.page);

  const url = ``;

  const bodyCarInfo = useSelector(state => state.data.bodyCars);

  const changeCity = (strCity) => {
    dispatch(setBodycar(strCity));
  }

  switch(page) {
    case 'delivery':
      return(
        <section className="calculatorDeliveryContainer">
          <article className = "calcNavigation">
            <button onClick={() => changeCity('Мини')} className="calcButton">Мини</button>
            <button onClick={() => changeCity('Седан')} className="calcButton">Седан</button>
            <button onClick={() => changeCity('Кроссовер')} className="calcButton">Кроссовер</button>
            <button onClick={() => changeCity('Джип')} className="calcButton">Джип</button>
            <button onClick={() => changeCity('Микроавтобус')} className="calcButton">Микроавтобус</button>
          </article>

          <div className="priceDescription">
            <h3>Стоимость доставки {bodyCarInfo}</h3>
            <p>Длиной не более 4.2 м</p>

          </div>

          <section className = "tableDelivery">
            <div className="oneRowDelivery">
                <div className="columnDelivery permament"><h4>Город</h4></div>
                <div className="columnDelivery permament"><h4>Расстояние</h4></div>
                <div className="columnDelivery permament"><h4>Время пути</h4></div>
                <div className="columnDelivery permament"><h4>Стоимость</h4></div>
            </div>
          </section>
        </section>
      )
    default:
      return(
        <section className="calculatorDeliveryContainer">
          <h2>Пу-пу-пууу...</h2>
        </section>
      )
  }

}

export default CalculatorDelivery;
