import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FormCallback from './FormCallBack';

const BigTitle = () => {

  const showForm = useSelector(state => state.shower.showHeaderForm);

  return(
    <div className="siteName">
      <div className="rowTitle"><h1 className="gray">АВТО</h1><h1 className="red">ЗАКАЗ</h1></div>
      {
        (!showForm) ? //Название или форма по клику /components/buttons/HeaderCall
                    <h2>ПОСТАВКА АВТО ИЗ ЯПОНИИ И КОРЕИ</h2>
                    :
                    <FormCallback />
      }
    </div>
  )
}

export default BigTitle;
