import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {router} from '../../redux/actions';

import YouTube from '../info/YouTube'; //Видео Ютуб
import News from '../info/News'; //Новости/Блог
import Korea from '../main/Korea'; //Корейские автомобили
import HotMenu from '../buttons/HotMenuButtons' //Хот меню
import StageWork from '../info/StageWork'; //Стадии работы
import BuyedCars from '../info/BuyedCars'; //Купленные машины

import Auctions from './Auctions'; //Фильтры поиска
import Statistic from './Statistic'; //Фильтры поиска
import Calculator from './Calculator'; //Страница Калькулятор
import Videos from './Videos'; //Страница видео
import Blogy from './Blogy'; //Страница блога
import Delivery from './Delivery'; //Страница Доставки
import Contacts from './Contacts'; //Страница контактов
import PublicOffer from './PublicOffer'; //
import PoliticalConf from './PoliticalConf'; //

const Router = ({page2}) => {

  const dispatch = useDispatch();
  const page = useSelector( state => state.router.page );

  useEffect(() => {
    dispatch(router(page2))
  },[page])

  switch (page) {
    case 'main':
      return(
        <section key = "key_main" className="mainContainer homePage">
          <YouTube />
          <News />
          <HotMenu />
          <StageWork />
          <BuyedCars />
        </section>
      )
  case 'bignews':
    return(
      <section key = "key_bignews" className="mainContainer newspage">
        <News />
      </section>
    )
  case 'koreaauto':
    return(
      <section key = "key_korea" className="mainContainer koreapage">
        <Korea />
      </section>
    )
  case 'publicoffert':
    return(
      <section key = "key_offer" className="mainContainer koreapage">
        <PublicOffer />
      </section>
    )
    case 'political':
      return(
        <section key = "key_political" className="mainContainer politPage">
          <PoliticalConf />
        </section>
      )
   case 'auctions':
      return(
        <section key = "key_auctions" className="mainContainer aucpage">
          <Auctions />
        </section>
      )
    case 'statistic':
       return(
         <section key = "key_stats" className="mainContainer statispage">
           <Statistic />
         </section>
       )
     case 'calculators':
        return(
          <section key = "key_calcs" className="mainContainer calulatorpage">
            <Calculator />
          </section>
        )
    case 'videos':
       return(
         <section key = "key_vides" className="mainContainer videospage">
           <Videos />
         </section>
       )
   case 'reviews':
      return(
        <section key = "key_rev" className="mainContainer reviewspage">
          <Videos />
        </section>
      )
    case 'blogs':
       return(
         <section key = "key_blogs" className="mainContainer blogpage">
           <Blogy />
         </section>
       )
   case 'delivery':
      return(
        <section key = "key_deliver" className="mainContainer deliverypage">
          <Delivery />
        </section>
      )
   case 'contacts':
      return(
        <section key = "key_contacts" className="mainContainer contactspage">
          <Contacts />
        </section>
      )
    default:
      return(
        <section className="mainContainer">
          <h2>Вы нашли очень странную страницу. Не находите ее больше пожалуйста :( </h2>
        </section>
      )
  }

}


export default Router;
