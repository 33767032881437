import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import stylesAuction from '../../css/auctions.css';
import {router} from '../../redux/actions';

const Auctions = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const page = useSelector(state => state.router.page);

  const goTo = (stringPage,evt) => {
    evt.preventDefault();
    if(stringPage !== 'main'){
      navigate("/"+stringPage)
      dispatch(router(stringPage));
    } else {
      navigate("/")
      dispatch(router(stringPage));
    }
  }

  switch (page) {

    case 'auctions':
      return(
        <section className="auctionContainer">
          <h2 className = "titlePage">Автомобильные аукционы Японии онлайн</h2>

          <article className="auctionResultContainer">
            <Filters />
            <div className="informationAuctionsContainer">
              <h3 className = "textInfoAuctions"> На этой странице присутствует информация только о будущих торгах на следующие 3 дня и еще нет цены продажи авто </h3>
              <span className = "miniTextInfoAuctions">Если вас интересуют реальные цены с учетом всех расходов - смотрите <a onClick = {(evt) => {goTo('statistic',evt)}} href = "#">Статистику продаж</a></span>
            </div>
            <ResultAuction />
          </article>

        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Auctions;
