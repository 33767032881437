import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import stylesStages from '../../css/stages.css';

import {router} from '../../redux/actions';

const StageWork = () => {

  const dispatch = useDispatch();

  const page = useSelector(state => state.router.page);
  const stages = useSelector(state => state.data.stages);

  switch(page) {
    case 'main':
      return(
        <section className="stageContainer">
          <h2 className = "titlePage">Этапы работы</h2>

          <section style = {{backgroundImage: `url(${process.env.PUBLIC_URL}/backgrounds/bg_stages.png)`}} className="wrapperStages">
          {
              (stages[0]) ?
                        stages.map( (item, index ) => {
                          return(
                            <article key = {`key_${item.title}_${index}`} className="oneStage">
                              <h3>{index+1}</h3>
                              <h5>{item.title}</h5>
                              <p>{item.description}</p>
                            </article>
                          )
                        })
                          : <h4>Нет стадий работы о_о</h4>
          }

          </section>
        </section>
      )
    default:
      return(
        <h4>Кнопка тут не нужна :( </h4>
      )
  }

}

export default StageWork;
