import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const LoadMore = () => {

  const page = useSelector(state => state.router.page);

  const loadmorekorea = () => {

  }

  switch(page) {
    case 'koreaauto':
      return(
        <button className = "loadmore" onClick={loadmorekorea}>Загрузить еще</button>
      )
    default:
      return(
        <h4>Кнопка тут не нужна :( </h4>
      )
  }

}

export default LoadMore;
