import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import styles from '../../css/news.css';

import News from '../info/News';

const Blogy = () => {

  const page = useSelector(state => state.router.page);

  const resultQuntity = 6;
  switch (page) {
    case 'blogs':
      return(
        <section className="blogContainer">
          <h2 className = "titlePage">Блог</h2>
          <h6 className = "titlePage">Горячее прямо из <a href = "https://t.me/auto_zakaz25">телеграм-канала</a></h6>
          <News />
        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Blogy;
