import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {router} from '../../redux/actions';
import footerStyles from '../../css/footer.css';

const Footer = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mypass,setMyPass] = useState('INSIDE360');
  const [counter,setCounter] = useState(0);

  const pasha = () => {
        let c = counter;
            c++;
        setCounter(c)
        console.log(counter)
        if(counter > 5) {
          setMyPass('Roman Agafonov :3')
        }
  }
  useEffect(()=>{},[mypass])
  const goTo = (stringPage,evt) => {
    evt.preventDefault();
    navigate("/"+stringPage)
    dispatch(router(stringPage));
    console.log(stringPage);
  }

  if(window.innerWidth > 798) {
    return(
      <section className="footerContainer">
        <div className="footerWrapper">

          <article className = "footerWidget footerWidget1">
            <a href="tel:8 984 144 21 21" target = "_blank">8 984 144 21 21</a>
            <a href="tel:8 914 690 25 90" target = "_blank">8 914 690 25 90</a>
            <p>Владивосток, ул. Днепровская 21, третий этаж</p>
          </article>

          <article className = "footerWidget footerWidget2">
            <a onClick = { evt => goTo('auctions',evt)} href = "#">Аукционы Японии</a>
            <a onClick = { evt => goTo('koreaauto',evt)} href = "#">Корейские авто</a>
            <a onClick = { evt => goTo('statistic',evt)} href = "#">Статистика</a>

            <span  onClick = { evt => goTo('publicoffert',evt)}  className = "stangeLink">Публичная оферта</span>
          </article>

          <article className = "footerWidget footerWidget3">
            <a onClick = { evt => goTo('calculators',evt)} href = "#">Калькуляторы</a>
            <a onClick = { evt => goTo('videos',evt)} href = "#">Видеообзоры</a>
            <a onClick = { evt => goTo('reviews',evt)} href = "#">Отзывы</a>

            <span  onClick = { evt => goTo('political',evt)}  className = "stangeLink">Политика конфиденциальности</span>
          </article>

          <article className = "footerWidget footerWidget4">
            <a onClick = { evt => goTo('blogs',evt)} href = "#">Блог</a>
            {
              //<a onClick = { evt => goTo('delivery',evt)} href = "#">Доставка в регионы</a>
            }
            <a onClick = { evt => goTo('contacts',evt)} href = "#">Контакты</a>
          </article>
        </div>

        <address style = {{userSelect: 'none'}} onClick = {pasha} ><div className = "copyright"><p>POWERED BY <strong>{mypass}</strong></p></div></address>
      </section>
    )
  } else {
    return(
      <section className="footerContainer">
        <div className="footerWrapper">

          <article className = "footerWidget footerWidget1">
            <a href="tel:8 984 144 21 21" target = "_blank">8 984 144 21 21</a>
            <a href="tel:8 914 690 25 90" target = "_blank">8 914 690 25 90</a>
            <p>Владивосток, ул. Днепровская 21, третий этаж</p>
          </article>

          <article className = "footerWidget footerWidget2">
            <a  onClick = { evt => goTo('auctions',evt)}  href = "#">Аукционы Онлайн</a>
            <a  onClick = { evt => goTo('koreaauto',evt)}  href = "#">Корейские авто</a>
            <a  onClick = { evt => goTo('statistic',evt)}  href = "#">Статистика</a>
            <a  onClick = { evt => goTo('videos',evt)} href = "#">Видео</a>
            <a  onClick = { evt => goTo('reviews',evt)} href = "#">Отзывы</a>
            <span  onClick = { evt => goTo('publicoffert',evt)} className = "stangeLink">Публичная оферта</span>
          </article>

          <article className = "footerWidget footerWidget3">
            <a  onClick = { evt => goTo('blogs',evt)}   href = "#">Блог</a>
            <a  onClick = { evt => goTo('contacts',evt)}   href = "#">Контакты</a>
            <a  onClick = { evt => goTo('calculators',evt)}  href = "#">Калькуляторы</a>
          </article>
        </div>

        <address onClick = {pasha} ><div className = "copyright"><p>POWERED BY <strong>{mypass}</strong></p></div></address>
      </section>
    )
  }
}

export default Footer;
