import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import stylesAuction from '../../css/auctions.css';

const Statistic = () => {

  const page = useSelector(state => state.router.page);
  const carsStat = useSelector(state => state.auctions.carsStat);

  const resultQuntity = 6;
  switch (page) {

    case 'statistic':
      return(
        <section className="auctionContainer">
          <h2 className = "titlePage">Статистика аукционов</h2>

          <article className="auctionResultContainer">
            <Filters />
            <div className="informationAuctionsContainer">
              <h3 className = "textInfoAuctions statisticH3"> Подборка автомобилей с аукционов Японии по вашим параметрам (найдено {carsStat.length}) </h3>
            </div>
            <ResultAuction />
          </article>

        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Statistic;
