import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона


import stylesContacts from '../../css/contacts.css';

const Contacts = () => {

  const page = useSelector(state => state.router.page);
  const url = useSelector(state => state.controller.getManagersApi);
  const [stopGet,setStopGet] = useState(false);

  const [managers,setManagers] = useState([{}]);

  const getManagers = async () => {

  }

  useEffect(() => {
    if(!stopGet) {
      let result;
        let res = fetch(url).then(res => res.json()).then(data => setManagers(data))
        setStopGet(true);
      }
  },[])

  const resultQuntity = 6;
  switch (page) {
    case 'contacts':
      return(
        <section className="contactsContainer">
          <h2 className = "titlePage">Контакты</h2>
          <article className = "upBlockContacts">
            <div className = "fristColumnContacts columns">
              <a className = "tel" href = "tel: 8 984 144 21 21"> 8 984 144 21 21 </a>
              <a className = "tel" href = "tel: 8 984 144 21 21"> 8 984 144 21 21 </a>

              <a href = "mailto:autozakaz@info.com">autozakaz@info.com</a>
              <span>Владивосток, ул. Днепровская, 21, третий этаж</span>
              <span className = "socialSpanBlock">Соц. сети:  <a target = "_blank" href = "https://t.me/auto_zakaz25"><img className = "tgSocial" src = {'/imgs/tg.png'} /></a><a target = "_blank" href = "https://www.youtube.com/c/%D0%90%D0%B2%D1%82%D0%BE%D0%97%D0%B0%D0%BA%D0%B0%D0%B7"><img className = "tgSocial" src = {'/imgs/youtube.svg'} /></a></span>
            </div>
            <div className = "secondColumnContacts columns">
              {
                managers.map((item,index) => {
                if(index < 4) {
                  return(
                    <div key = {`${item.name}_${index}`}>
                      <h3>{item.name}</h3>
                      <a href = {`tel:${item.tel}`}> {`${item.tel}`} </a>
                    </div>
                  )
                  }
                  })
              }
            </div>
            <div className = "threedColumnContacts columns">
            {
              managers.map((item,index) => {
              if(index >= 4) {
                return(
                  <div key = {`${item.name}_${index}`}>
                    <h3>{item.name}</h3>
                    <a href = {`tel:${item.tel}`}> {`${item.tel}`} </a>
                  </div>
                )
                }
                })
            }
            </div>
          </article>
          <article className = "downBlockContacts">
            <h3>Банковские реквизиты</h3>
            <p>ИП «Селезнев Максим Вячеславович»</p>
            <p>ИНН 250818340283</p>
            <p>ОГРН 317253600092564</p>
            <p>БИК 040813770</p>
          </article>
          <div className = "iframeMap"><a href="https://yandex.ru/maps/org/avtozakaz/140791872893/?utm_medium=mapframe&utm_source=maps">АвтоЗаказ</a><a href="https://yandex.ru/maps/75/vladivostok/category/auto_auction/154363938388/?utm_medium=mapframe&utm_source=maps">Автоаукцион во Владивостоке</a><a href="https://yandex.ru/maps/75/vladivostok/category/car_market/1363851944/?utm_medium=mapframe&utm_source=maps">Авторынок во Владивостоке</a><iframe src="https://yandex.ru/map-widget/v1/-/CCUbrSt9LB" frameborder="1" allowfullscreen="true"></iframe></div>
        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Contacts;
