import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import styles from './css/404.css';


export default function Error() {

  const navigate = useNavigate();

  return(
    <div className="errorContainer">
      <h1 className="header404">404 Страница не найдена!</h1>
      <h2 className="header404">Вы нашли страницу которой нет!</h2>
      <a onClick = {() => navigate('/')} href = ""> Вернуться </a>
    </div>
  )
}
