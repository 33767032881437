import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {fetchNews, newsLoader, router, setBigNews} from '../../redux/actions';

import stylesNews from '../../css/news.css';

import Gallery from './Gallery';

const News = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newsList = useSelector(state => state.info.newsList);
  const loading =  useSelector(state => state.info.newsLoader);
  const bigNews = useSelector(state => state.info.bigNews);
  const domain = useSelector(state => state.controller.domain);
  const newsUrl = useSelector(state => state.controller.getNewsApi);

  const page = useSelector(state => state.router.page);

  const toggler = (element) => {
    element.classList.toggle('bigPicture');
  }

  const toBigNews = (itemNews) => {
    window.scrollTo(0,0);
    dispatch(router('bignews'))
    navigate('/bignews');
    dispatch(setBigNews(itemNews))
  }

  if(!loading) {
    dispatch(newsLoader(true));
    dispatch(fetchNews(newsUrl)); // ДОПИСАТЬ
  }

  switch(page) {
    case 'main':
      return(
        <section className="newsContainer">
          {
            (newsList[0][0]) ?
                            newsList[0].map( (item, index ) => {
                              if(index<3) {
                              return <div key = {`keyNews_${item.date}_main_${index}`} onClick = {() => toBigNews(item)} className = "miniBlockNews">
                                      <img className="thumbNews" src={domain+item.imgs[0]} />
                                      <span className = "dateNews"> {item.date} </span>
                                      <h3 className = "titleNews"> {item.title} </h3>
                                    </div>
                                  }
                            })
                           :
                           <h3>Новостей нет :( </h3>
          }
        </section>
      )
    case 'blogs':
      return(
        <section className="newsBigContainer">
          {
            (newsList[0][0]) ?
                            newsList[0].map( (item, index ) => {
                              if(index<9) {
                              return <div key = {`keyNews_${item.date}_blog_${index}`} onClick = {() => toBigNews(item)} className = "miniBlockNews">
                                      <img className="thumbNews" src={domain+item.imgs[0]} />
                                      <span className = "dateNews"> {item.date} </span>
                                      <h3 className = "titleNews"> {item.title} </h3>
                                    </div>
                                  }
                            })
                           :
                           <h3>Новостей нет :( </h3>
          }
        </section>
      )
    case 'bignews':
      return(
        <section className="newsContainer">
          {
            (bigNews && bigNews.imgs) ?
                        <div className = "bigBlockNews">

                            <h4 className = "titleBlock">Новости</h4>
                            <h3 className = "titleBigNews"> {bigNews.title} </h3>
                            <span className = "dateBigNews"> {bigNews.fullpost} </span>
                            {
                              /*
                            <h4 className = "infoAboutCar">Год выпуска: {bigNews.age} </h4>
                            <h4 className = "infoAboutCar">Дата покупки: {bigNews.date} </h4>
                            <h4 className = "infoAboutCar">Пробег: {bigNews.milage} </h4>
                            <h4 className = "infoAboutCar">Цена: {bigNews.price} </h4>
                            <h4 className = "infoAboutCar">Оценка: {bigNews.review} </h4>
                            */
                           }
                            <h3 className = "titleBigNews"> Фотографии </h3>
                            <section className = "postGallery">
                            {
                              <Gallery domain = {domain}  dir = "hor" imgs = {bigNews.imgs} />
                            }
                            </section>
                            <h3 className = "managerNews"><a href = {`tel:${bigNews.contacts}`}>Позвонить менеджеру</a></h3>
                        </div>
                      :
                        <h3>Вы нашли секретную страничку ;) </h3>
          }
        </section>
      )
    default:
      return(
        <section className="NewsContainer">
          <h2> На этой странице новостей, увы, нет :( </h2>
        </section>
      )
  }

}

export default News;
