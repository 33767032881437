import React from 'react';
import {useDispatch,useSelector} from 'react-redux';

import styleCallMe from '../../css/header.css';

import {showPop} from '../../redux/actions';

const CallMe = ({setterData,data}) => {

  const dispatch = useDispatch();

  return(
    <button onClick={() => dispatch(showPop(true))} className="headerCallMe">
      <strong className = "clickMe">Обратный звонок</strong>
    </button>
  )
}

export default CallMe;
