import React from 'react';

import {FETCH_BUYEDCARS, FETCH_ADDKOREACARS,FETCH_GETKOREACARS ,BODYCAR, SHOW_POP, NAMEFORM, TELFORM, CITYFORM, PARAMSCAR, ALLSUM} from './types';
//SHOW_POP
//NAMEFORM
//TELFORM
//CITYFORM

const initialState = {
  //Данные по стадиям работы
  nameForm: '',
  telForm: '',
  cityForm: '',
  allSum: 0,
  paramsCarsToSend: {},
  stages: [
    {
      title: 'Заключение договора',
      description: `Прописываем характеристики
                    и конечную стоимость`,
    },
    {
      title: 'Предоплата',
      description: `Вы вносите депозит от 55.000 руб. (входит в конечную стоимость авто)`,
    },
    {
      title: 'Подбираем автомобиль',
      description: `Подбираем для вас варианты
                    (с подробным описанием и фото)
                    на автоаукционах.`,
    },
    {
      title: 'Покупка',
      description: `Делаем ставки в рамках вашего бюджета`,
    },
    {
      title: 'Оплата',
      description: `После покупки, оплачиваете
                    стоимость автомобиля и его
                    доставку из Японии`,
    },
    {
      title: 'Оформление документов',
      description: `Мы занимаемся таможенным
                    оформлением и получением
                    документов.`,
    },
    {
      title: 'Растаможка',
      description: `Вносится последняя
                    часть оплаты – расходы
                    за таможенное оформление.`,
    },
    {
      title: 'Доставка',
      description: `Отправляем транспортной
                    компанией до вашего города`,
    },
  ],
  //Купленные авто
  /*
  {
    thumb: process.env.PUBLIC_URL+'/imgs/buyedcar.png',
    carName: 'toyota corolla touring wagon',
    lotNumber: '2304403',
    buyDate: '10.08.2022',

    bodyCar: 'AE104 G',
    vCar: '15B7',
    milageCar: 200000,
    yearCar: 1999,
    kpp: 'FA',
    reviewNumber: 3,

    priceInVDK: 5400000,
    priceInJAPAN: 300000,
  }
  */
  buyedCars: [
  ],
  cityes: [
        'Абакан',
        'Азов',
        'Александров',
        'Алексин',
        'Альметьевск',
        'Анапа',
        'Ангарск',
        'Анжеро-Судженск',
        'Апатиты',
        'Арзамас',
        'Армавир',
        'Арсеньев',
        'Артем',
        'Архангельск',
        'Асбест',
        'Астрахань',
        'Ачинск',
        'Балаково',
        'Балахна',
        'Балашиха',
        'Балашов',
        'Барнаул',
        'Батайск',
        'Белгород',
        'Белебей',
        'Белово',
        'Белогорск (Амурская область)',
        'Белорецк',
        'Белореченск',
        'Бердск',
        'Березники',
        'Березовский (Свердловская область)',
        'Бийск',
        'Биробиджан',
        'Благовещенск (Амурская область)',
        'Бор',
        'Борисоглебск',
        'Боровичи',
        'Братск',
        'Брянск',
        'Бугульма',
        'Буденновск',
        'Бузулук',
        'Буйнакск',
        'Великие Луки',
        'Великий Новгород',
        'Верхняя Пышма',
        'Видное',
        'Владивосток',
        'Владикавказ',
        'Владимир',
        'Волгоград',
        'Волгодонск',
        'Волжск',
        'Волжский',
        'Вологда',
        'Вольск',
        'Воркута',
        'Воронеж',
        'Воскресенск',
        'Воткинск',
        'Всеволожск',
        'Выборг',
        'Выкса',
        'Вязьма',
        'Гатчина',
        'Геленджик',
        'Георгиевск',
        'Глазов',
        'Горно-Алтайск',
        'Грозный',
        'Губкин',
        'Гудермес',
        'Гуково',
        'Гусь-Хрустальный',
        'Дербент',
        'Дзержинск',
        'Димитровград',
        'Дмитров',
        'Долгопрудный',
        'Домодедово',
        'Донской',
        'Дубна',
        'Евпатория',
        'Егорьевск',
        'Ейск',
        'Екатеринбург',
        'Елабуга',
        'Елец',
        'Ессентуки',
        'Железногорск (Красноярский край)',
        'Железногорск (Курская область)',
        'Жигулевск',
        'Жуковский',
        'Заречный',
        'Зеленогорск',
        'Зеленодольск',
        'Златоуст',
        'Иваново',
        'Ивантеевка',
        'Ижевск',
        'Избербаш',
        'Иркутск',
        'Искитим',
        'Ишим',
        'Ишимбай',
        'Йошкар-Ола',
        'Казань',
        'Калининград',
        'Калуга',
        'Каменск-Уральский',
        'Каменск-Шахтинский',
        'Камышин',
        'Канск',
        'Каспийск',
        'Кемерово',
        'Керчь',
        'Кинешма',
        'Кириши',
        'Киров (Кировская область)',
        'Кирово-Чепецк',
        'Киселевск',
        'Кисловодск',
        'Клин',
        'Клинцы',
        'Ковров',
        'Когалым',
        'Коломна',
        'Комсомольск-на-Амуре',
        'Копейск',
        'Королев',
        'Кострома',
        'Котлас',
        'Красногорск',
        'Краснодар',
        'Краснокаменск',
        'Краснокамск',
        'Краснотурьинск',
        'Красноярск',
        'Кропоткин',
        'Крымск',
        'Кстово',
        'Кузнецк',
        'Кумертау',
        'Кунгур',
        'Курган',
        'Курск',
        'Кызыл',
        'Лабинск',
        'Лениногорск',
        'Ленинск-Кузнецкий',
        'Лесосибирск',
        'Липецк',
        'Лиски',
        'Лобня',
        'Лысьва',
        'Лыткарино',
        'Люберцы',
        'Магадан',
        'Магнитогорск',
        'Майкоп',
        'Махачкала',
        'Междуреченск',
        'Мелеуз',
        'Миасс',
        'Минеральные Воды',
        'Минусинск',
        'Михайловка',
        'Михайловск (Ставропольский край)',
        'Мичуринск',
        'Москва',
        'Мурманск',
        'Муром',
        'Мытищи',
        'Набережные Челны',
        'Назарово',
        'Назрань',
        'Нальчик',
        'Наро-Фоминск',
        'Находка',
        'Невинномысск',
        'Нерюнгри',
        'Нефтекамск',
        'Нефтеюганск',
        'Нижневартовск',
        'Нижнекамск',
        'Нижний Новгород',
        'Нижний Тагил',
        'Новоалтайск',
        'Новокузнецк',
        'Новокуйбышевск',
        'Новомосковск',
        'Новороссийск',
        'Новосибирск',
        'Новотроицк',
        'Новоуральск',
        'Новочебоксарск',
        'Новочеркасск',
        'Новошахтинск',
        'Новый Уренгой',
        'Ногинск',
        'Норильск',
        'Ноябрьск',
        'Нягань',
        'Обнинск',
        'Одинцово',
        'Озерск (Челябинская область)',
        'Октябрьский',
        'Омск',
        'Орел',
        'Оренбург',
        'Орехово-Зуево',
        'Орск',
        'Павлово',
        'Павловский Посад',
        'Пенза',
        'Первоуральск',
        'Пермь',
        'Петрозаводск',
        'Петропавловск-Камчатский',
        'Подольск',
        'Полевской',
        'Прокопьевск',
        'Прохладный',
        'Псков',
        'Пушкино',
        'Пятигорск',
        'Раменское',
        'Ревда',
        'Реутов',
        'Ржев',
        'Рославль',
        'Россошь',
        'Ростов-на-Дону',
        'Рубцовск',
        'Рыбинск',
        'Рязань',
        'Салават',
        'Сальск',
        'Самара',
        'Санкт-Петербург',
        'Саранск',
        'Сарапул',
        'Саратов',
        'Саров',
        'Свободный',
        'Севастополь',
        'Северодвинск',
        'Северск',
        'Сергиев Посад',
        'Серов',
        'Серпухов',
        'Сертолово',
        'Сибай',
        'Симферополь',
        'Славянск-на-Кубани',
        'Смоленск',
        'Соликамск',
        'Солнечногорск',
        'Сосновый Бор',
        'Сочи',
        'Ставрополь',
        'Старый Оскол',
        'Стерлитамак',
        'Ступино',
        'Сургут',
        'Сызрань',
        'Сыктывкар',
        'Таганрог',
        'Тамбов',
        'Тверь',
        'Тимашевск',
        'Тихвин',
        'Тихорецк',
        'Тобольск',
        'Тольятти',
        'Томск',
        'Троицк',
        'Туапсе',
        'Туймазы',
        'Тула',
        'Тюмень',
        'Узловая',
        'Улан-Удэ',
        'Ульяновск',
        'Урус-Мартан',
        'Усолье-Сибирское',
        'Уссурийск',
        'Усть-Илимск',
        'Уфа',
        'Ухта',
        'Феодосия',
        'Фрязино',
        'Хабаровск',
        'Ханты-Мансийск',
        'Хасавюрт',
        'Химки',
        'Чайковский',
        'Чапаевск',
        'Чебоксары',
        'Челябинск',
        'Черемхово',
        'Череповец',
        'Черкесск',
        'Черногорск',
        'Чехов',
        'Чистополь',
        'Чита',
        'Шадринск',
        'Шали',
        'Шахты',
        'Шуя',
        'Щекино',
        'Щелково',
        'Электросталь',
        'Элиста',
        'Энгельс',
        'Южно-Сахалинск',
        'Юрга',
        'Якутск',
        'Ялта',
        'Ярославль',

  ],
  cityesDelivery: [
    '',
  ],
  /*
  {
    name: "KIA RIO CLASSIC, 2019",
    thumb: thumbKorea,
    price: 2000000,
  },
  */
  stopperKorea: [
  ],
  bodyCars: 'Мини',
  //Типы для админки (Меню и контент)
  typesAdminContent: [
    {
      id: 0,
      title: 'Админ Панель',
      alt: '',
      description: ``,
      icon: '',
    },
    {
      id: 1,
      title: 'Купленные машины',
      alt: '',
      description: ``,
      icon: '',
    },
    {
      id: 2,
      title: 'Корейские авто',
      alt: '',
      description: ``,
      icon: '',
    },
    {
      id: 3,
      title: 'Настройки калькулятора',
      alt: '',
      description: ``,
      icon: '',
    },
    {
      id: 4,
      title: 'Настройки сайта',
      alt: '',
      description: ``,
      icon: '',
    },
  ],
}

export const dataReducer = (state = initialState ,action) => {
  switch (action.type) {
    case FETCH_BUYEDCARS: {
      return {...state, buyedCars: action.payload}
    }
    case FETCH_GETKOREACARS: {
      return {...state, stopperKorea: action.payload}
    }
    case BODYCAR: {
      return {...state, bodyCars: [action.payload]}
    }
    case NAMEFORM: {
      return {...state, nameForm: action.payload}
    }
    case TELFORM: {
      return {...state, telForm: action.payload}
    }
    case CITYFORM: {
      return {...state, cityForm: action.payload}
    }
    case PARAMSCAR: {
      return {...state, paramsCarsToSend: action.payload}
    }
    case ALLSUM: {
      return {...state, allSum: action.payload}
    }
    default: return state;
  }
}
