import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import wPhoneIcone from '../../includes/imgs/white_phone_icon.svg';
import wTg from '../../includes/imgs/white_tg_icon.svg';

const NarrowHeader = () => {

  const narrowHeader = 'narrowHeader';

  return(
    <div className = {`${narrowHeader}`}>
      <div className = 'narrowWrapper'>

        <article className = "narrowPhonesWrapper">

          <img className = "whitePhoneIcon" src = {wPhoneIcone} />

          <a href = "tel:8 914 690 25 90">8 914 690 25 90</a>
           <span> / </span>
          <a href = "tel:8 984 144 21 21"> 8 984 144 21 21 </a>

          <a target = "_blank" href = "https://t.me/auto_zakaz25"><img className = "whiteTg" src = {wTg} /></a>

        </article>

        <h4>Владивосток, ул. Днепровская 21, третий этаж</h4>
      </div>
    </div>
  )
}


export default NarrowHeader;
