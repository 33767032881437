import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FETCH_VIDEOS, FETCH_VIDEOS_ALL , VIDEO_LOADER, VIDEO_LOADER_ALL, FETCH_NEWS, NEWS_LOADER, BIG_NEWS, MANAGERS} from './types';

//https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLnWa3X9LExneuRAdZpS02EpH1ZwcTGemT&key=AIzaSyBeQt8H6LiJ3hkoPtex3BZo_O2e7qRx0PU
const initialState = {
    youtubeAPI: "AIzaSyDI5MFDAiyZ-KtHlLJD5tjgifSbmwjSUws",
    idPlaylist: "PLnWa3X9LExneuRAdZpS02EpH1ZwcTGemT",
    videos: [],
    managers: [{
      name: 'Test',
      login: 'test',
      lvl: '1',
      password: '1231123',
      tel: '899922254555',
    }],
    allVideos: [],
    newsList: [
      {
        date: "",
        title: "",
        description: "",
        dataPost: "",
        thumb: "",
      },
    ],
    bigNews: {
      date: "",
      title: "",
      description: "",
      dataPost: "",
      thumb: "",
    },
    newsLoader: false,
    videoLoader: false,
    allVideoLoader: false,
}

export const infoReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_VIDEOS:
      return {...state, videos: [action.payload]}
    case FETCH_VIDEOS_ALL:
      return {...state, allVideos: [action.payload]}
    case VIDEO_LOADER:
      return {...state, videoLoader: action.payload}
    case VIDEO_LOADER_ALL:
      return {...state, allVideoLoader: action.payload}
    case NEWS_LOADER:
      return {...state, newsLoader: action.payload}
    case FETCH_NEWS:
      return {...state, newsList: [action.payload]}
    case BIG_NEWS:
      return { ...state, bigNews: action.payload}
    default:
      return state;
  }

}
