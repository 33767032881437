import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {showForm} from '../../redux/actions';
import {sendMail} from '../../workers/apiworkers'

const HeaderCall = () => {

  const nameForm = useSelector(state => state.data.nameForm);
  const telForm = useSelector(state => state.data.telForm);
  const mailurl = useSelector(state => state.controller.mailer);
  const skey = useSelector(state => state.controller.secretKey);


  const dispatch = useDispatch();
  const checkShower = useSelector(state => state.shower.showHeaderForm);

  const [textButton,setTextButton] = useState('Оставить заявку');

  const toggleForm = async (evt) => {
    if(textButton == 'Ваша заявка отправлена!') return;
    if(checkShower) {
      dispatch(showForm(false));
      sendMail(mailurl,skey,[nameForm,telForm]);
      setTextButton('Ваша заявка отправлена!');
      await setTimeout(() => {
        setTextButton('Оставить заявку');
      },2000);
    } else {
      dispatch(showForm(true));
      setTextButton('Отправить')
    }
  }
  return(
    <button className="headerCallButton" onClick={(evt) => toggleForm(evt)}>{textButton}</button>
  )

}

export default HeaderCall;
