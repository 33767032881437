import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {getMarksApi, getModelApi, getKuzovApi, getVolumerApi, getColorApi,getResultApi, getResultStatsApi} from '../../redux/actions';

import {customSelectStyles} from '../../css/selectstyles';

import {makeAucQuery} from '../../workers/apiworkers';

const Filters = () => {

  const dispatch = useDispatch();

  const page = useSelector(state => state.router.page);

  const mark = useSelector(state => state.auctions.mark);
  const model = useSelector(state => state.auctions.model);
  const body = useSelector(state => state.auctions.body);
  const age = useSelector(state => state.auctions.year);
  const color = useSelector(state => state.auctions.color);
  const review = useSelector(state => state.auctions.review);
  const volume = useSelector(state => state.auctions.volume);
  const millagearray = useSelector(state => state.auctions.millagearray)

  const getMarks = useSelector(state => state.controller.getMarks);

  const [carToSend, sendCarToSend] = useState({
    'mark': '',
    'body': '',
    'model': '',
    'price': '',
    'review': '',
    'color': '',
    'year': '',
    'volume': '',
    'millage': '',
  });

  const markOptions = mark.map((item,index) => {
    if(!item && index === 0) {
      return {value: 'Выберите марку', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const modelOptions = model.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const bodyOptions = body.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const ageOptions = age.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const milOptions = millagearray.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const revOptions = review.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const colOptions = color.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const volOptions = volume.map(item => {
    if(!markOptions[0]) {
      return {value: '', label: 'Выберите марку'}
    }
    return {value: item, label: item}
  });
  const showData = (value) => {

  }

  //Ченджт селектов
  const getModelsCar = (value) => {
    dispatch(getModelApi(value));
  }
  //Ченджт селектов
  const getAllforModel = (value) => {
    dispatch(getKuzovApi(value));
    dispatch(getColorApi(value));
    dispatch(getVolumerApi(value));
  }
  const setCarSender = (string,value) => {
    let temp = carToSend;
        temp[string] = value;
    sendCarToSend(temp);
  }
  const getResultsStatsCar = () =>{
    for(let prop in carToSend) {
      if(!prop) return false;
    }
    dispatch(getResultStatsApi(carToSend));
  }
  const getResultsCar = () =>{
    for(let prop in carToSend) {
      if(!prop) return false;
    }
    dispatch(getResultApi(carToSend));
  }
  useEffect(() => {
    if(!mark[0]) dispatch(getMarksApi(getMarks));
  },[getMarks])

  switch (page) {

    case 'auctions':
      if(window.innerWidth > 798) {
        return(
          <section className = "FilterContainer">
            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Марка авто</span>
                <Select placeholder = "Выбрать марку" className='react-select-container' onChange = {(value) => {getModelsCar(value); setCarSender('mark',value.value)}} classNamePrefix="react-select" options = {markOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Кузов</span>
                <Select placeholder = "Выбрать Кузов" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('body',value.value)}} classNamePrefix="react-select" options = {bodyOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Стоимость до в ¥</span>
                <input placeholder = "Примерная стоимость" className='react-select-container' onChange = {(value) => {showData(value); setCarSender('price',value.target.value)}} classNamePrefix="react-select" />
              </div>
            </div>

            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Модель авто</span>
                <Select placeholder = "Выбрать модель" className='react-select-container' onChange = {(value) => { getAllforModel(value); setCarSender('model',value.value)}} classNamePrefix="react-select" options = {modelOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Цвет</span>
                <Select placeholder = "Выберите цвет" className='react-select-container' onChange = {(value) => {showData(value.value);setCarSender('color',value.value)}} classNamePrefix="react-select" options = {colOptions} />
              </div>
            </div>

            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Год выпуска</span>
                <Select placeholder = "Выбрать год" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('year',value.value)}} classNamePrefix="react-select" options = {ageOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Объем</span>
                <Select placeholder = "Объем двигателя" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('volume',value.value)}} classNamePrefix="react-select" options = {volOptions} />
              </div>
            </div>
            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Пробег до</span>
                <Select placeholder = "Пробег" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('millage',value.value)}} classNamePrefix="react-select" options = {milOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Оценка</span>
                <Select placeholder = "Оценка" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('review',value.value)}} classNamePrefix="react-select" options = {revOptions} />
              </div>
            </div>
            <button key = "filterButtonAuc"  onClick = {(value) => {showData(value); getResultsCar()}} className = "typicalButton">ПРИМЕНИТЬ ФИЛЬТР</button>
          </section>
        )
      } else {
        return(
        <section className = "FilterContainer">
          <div className = "filterColumn">
            <div className = "miniFilterColumn">
              <span className = "graySpan">Марка авто</span>
              <Select placeholder = "Выбрать марку" className='react-select-container' onChange = {(value) => {getModelsCar(value); setCarSender('mark',value.value)}} classNamePrefix="react-select" options = {markOptions} />
            </div>
            <div className = "miniFilterColumn">
              <span className = "graySpan">Модель авто</span>
              <Select placeholder = "Выбрать модель" className='react-select-container' onChange = {(value) => { getAllforModel(value); setCarSender('model',value.value)}} classNamePrefix="react-select" options = {modelOptions} />
            </div>
            <div className = "miniFilterColumn">
              <span className = "graySpan">Кузов</span>
              <Select placeholder = "Выбрать Кузов" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('body',value.value)}} classNamePrefix="react-select" options = {bodyOptions} />
            </div>
          </div>

          <div className = "filterColumn">

            <div className = "miniFilterColumn">
              <span className = "graySpan">Цвет</span>
              <Select placeholder = "Выберите цвет" className='react-select-container' onChange = {(value) => {showData(value.value);setCarSender('color',value.value)}} classNamePrefix="react-select" options = {colOptions} />
            </div>
          </div>
          <div className = "filterColumn">
            <div className = "miniFilterColumn">
              <span className = "graySpan">Год выпуска</span>
              <Select placeholder = "Выбрать год" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('year',value.value)}} classNamePrefix="react-select" options = {ageOptions} />
            </div>
            <div className = "miniFilterColumn">
              <span className = "graySpan">Объем</span>
              <Select placeholder = "Объем двигателя" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('volume',value.value)}} classNamePrefix="react-select" options = {volOptions} />
            </div>
            <div className = "miniFilterColumn">
              <span className = "graySpan">Стоимость до в ¥</span>
              <input placeholder = "Примерная стоимость" className='react-select-container' onChange = {(value) => {showData(value); setCarSender('price',value.target.value)}} classNamePrefix="react-select" />
            </div>
          </div>
          <div className = "filterColumn">
            <div className = "miniFilterColumn">
              <span className = "graySpan">Пробег до</span>
              <Select placeholder = "Пробег" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('millage',value.value)}} classNamePrefix="react-select" options = {milOptions} />
            </div>
            <div className = "miniFilterColumn">
              <span className = "graySpan">Оценка</span>
              <Select placeholder = "Оценка" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('review',value.value)}} classNamePrefix="react-select" options = {revOptions} />
            </div>
          </div>
          <button key = "filterButtonAuc" onClick = {(value) => {showData(value); getResultsCar()}} className = "typicalButton">ПРИМЕНИТЬ ФИЛЬТР</button>
        </section>
      )
      }
      case 'statistic':
        return(
          <section className = "FilterContainer">
            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Марка авто</span>
                <Select placeholder = "Выбрать марку" className='react-select-container' onChange = {(value) => {getModelsCar(value); setCarSender('mark',value.value)}} classNamePrefix="react-select" options = {markOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Кузов</span>
                <Select placeholder = "Выбрать Кузов" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('body',value.value)}} classNamePrefix="react-select" options = {bodyOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Стоимость до в ¥</span>
                <input placeholder = "Примерная стоимость" className='react-select-container' onChange = {(value) => {showData(value); setCarSender('price',value.target.value)}} classNamePrefix="react-select" />
              </div>
            </div>

            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Модель авто</span>
                <Select placeholder = "Выбрать модель" className='react-select-container' onChange = {(value) => { getAllforModel(value); setCarSender('model',value.value)}} classNamePrefix="react-select" options = {modelOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Цвет</span>
                <Select placeholder = "Выберите цвет" className='react-select-container' onChange = {(value) => {showData(value.value);setCarSender('color',value.value)}} classNamePrefix="react-select" options = {colOptions} />
              </div>
            </div>

            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Год выпуска</span>
                <Select placeholder = "Выбрать год" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('year',value.value)}} classNamePrefix="react-select" options = {ageOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Объем</span>
                <Select placeholder = "Объем двигателя" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('volume',value.value)}} classNamePrefix="react-select" options = {volOptions} />
              </div>
            </div>
            <div className = "filterColumn">
              <div className = "miniFilterColumn">
                <span className = "graySpan">Пробег до</span>
                <Select placeholder = "Пробег" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('millage',value.value)}} classNamePrefix="react-select" options = {milOptions} />
              </div>
              <div className = "miniFilterColumn">
                <span className = "graySpan">Оценка</span>
                <Select placeholder = "Оценка" className='react-select-container' onChange = {(value) => {showData(value);setCarSender('review',value.value)}} classNamePrefix="react-select" options = {revOptions} />
              </div>
            </div>
            <button key = "filterButtonStat"  onClick = {(value) => {showData(value); getResultsStatsCar()}} className = "typicalButton">ПРИМЕНИТЬ ФИЛЬТР</button>
          </section>
        )
    default:
      return(
        <h4> Вас так и тянет в неизведанное о-о. Ошибка отображения </h4>
      )
  }
}

export default Filters;
