import React, {useEffect, useState, useRef} from 'react';
import Select from 'react-select';
import ReCAPTCHA from 'react-google-recaptcha';

import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import stylesCalculator from '../../css/calculator.css';

import {showPop, setSum} from '../../redux/actions';

const Calculator = () => {

  const dispatch = useDispatch();

  const page = useSelector(state => state.router.page);
  const allSum2 = useSelector(state => state.data.allSum);

  const toTop = useRef();

  const [captcha,setCaptcha] = useState(false);

  const total = useRef(null);

  const [priceY,setPriceY] = useState('');
  const [year,setYear] = useState('');
  const [priceUSD,setPriceUSD] = useState('');
  const [volume,setVolume] = useState('');
  const [age, setAge] = useState('');
  const [power,setPower] = useState('');
  const [fuel,setFuel] = useState('');
  const [htmlTable, setHtmlTable] = useState(``);
  const [who, setWho] = useState('fiz');
  const [middlePriceDelivery, setMiddlePriceDelivery] = useState(0);

  const [usdR, setUsdR] = useState('');
  const [usdY, setUsdY] = useState('');
  const [poshlina, setPoshlina] = useState(0);

  const [calcData, setCalcData] = useState(0);
  const [allSum, setAllSum] = useState(0);

  const [yearNow, setYearNow] = useState(new Date().getFullYear());

  const aucOptions = [
    {value: 'По умолчанию (22.000Y)', label: 'По умолчанию (22.000Y)'},
  ]
  const [selectAuc,setSelectAuc] = useState('');

  const [fromIs,setFromIs] = useState('japan');

  const [endPrice,setEndPrice] = useState(0);

  const secretKey = useSelector(state => state.controller.secretKey);
  const urlCalc = useSelector(state => state.controller.calcApi);

  let cityes = useSelector(state => state.data.cityes);
      cityes = cityes.map(city => {
        return { value: city, label: city }
      })

  const getResultCalc = () => {
    setTimeout(() => {
      window.scrollTo(0, toTop.current.scrollHeight);
    },200)

    setHtmlTable(``);
    let tempYear = yearNow - age;
    const newFormData = new FormData();
          newFormData.append('year',year)
          newFormData.append('getprice',(fromIs == 'japan') ? 'japan' : 'korea')
          newFormData.append('volume',volume)
          newFormData.append('power',power)
          newFormData.append('fuel',fuel)
          newFormData.append('priceY',priceY)
          newFormData.append('age',tempYear);
          newFormData.append('who',who);
          if(age == 4) {
            newFormData.append('prohod',1);
            console.log('ПРОХОДИТ-----------------------------------------------------=---')
          } else {
            newFormData.append('prohod',0);
            console.log('НЕ ПРОХОДИТ------------------------------------------------------')
          }

    if(captcha) {

      fetch(urlCalc, {
        method: 'POST',
        body: newFormData,
      }).then(res => res.json()).then(data => {
            let tempData = data;
            let tempAllCurrency = tempData.info.currency;
                tempAllCurrency = tempAllCurrency.matchAll(/-?\d+(\.\d+)?/g);
                tempAllCurrency = Array.from(tempAllCurrency)

            setUsdY(tempAllCurrency[0][0]);
            setUsdR(tempAllCurrency[2][0])

            let tempSum = tempData.sum;
                tempSum = parseFloat(tempSum).toFixed(0);
                setAllSum(parseFloat(tempSum*usdR).toFixed(0));

                let tempSumToLoad = parseFloat(tempSum*usdR).toFixed(0);
                dispatch(setSum(tempSumToLoad))
                console.log(allSum2)
            setCalcData(tempData);
            setTimeout(()=> {
              dispatch(setSum(tempSumToLoad))
              setCalcData(tempData);
              dispatch(setSum(parseFloat(tempSum*usdR).toFixed(0)))
              console.log(allSum2)
            },300)

      }).catch(e => console.error(e))

    }
  }
  const getResultCaptcha = (value) => {
    if(priceY < 2) setPriceY(10000000)
    setCaptcha(value);
  }

  useEffect(()=>{},[who,usdR, usdY,allSum2])
  useEffect(()=>{},[])
  useEffect(()=>{
    console.log('СТЕЙТ ОБНОВИЛСЯ СУКА')
  },[calcData,allSum, poshlina, fromIs])

  switch (page) {

    case 'calculators':
      return(
        <section className="calculatorContainer">
          <h2 ref = {toTop}  className = "titlePage">Калькулятор стоимости</h2>
          <h4 className = "miniTitlePage">Онлайн расчет средней стоимости покупки и доставки автомобиля.</h4>
          <p className = "descriptionPage">Автомобили 2007 года и старше запрещены к ввозу под полную пошлину с выдачей ПТС.</p>

          <article className="calculatorResultContainer">


              <div className = "oneColumn">

                <div className = "calculatorHeaderButton">
                  <button onClick={(evt) =>{
                    setFromIs('japan');
                    setCalcData({});
                  }} className = {`calculatorButton ${(fromIs == 'japan') ? 'activeCalcButton' : ''}`}>Авто из Японии</button>
                  <button onClick={(evt) =>{
                     setFromIs('korea');
                     setCalcData({});
                   }}  className = {`calculatorButton ${(fromIs == 'korea') ? 'activeCalcButton' : ''}`}>Авто из Кореи</button>
                </div>

              <form className = "stopper">
                <div className = "miniFilterColumn">
                  <span className = "graySpan">{ (fromIs == 'japan') ? 'Аукционная стоимость (йены)' : 'Аукционная стоимость (воны)'}</span>
                  <input onChange = {(evt) => {setPriceY(evt.target.value)}}  className = "textCalculator" />
                </div>

                <div style = {{marginTop: "30px"}} className = "radioButtons">
                  <span className = "graySpan">Тип топлива</span>
                  <div className = "rowRadio">
                    <input onChange = {(evt) => {setFuel(evt.target.value); console.log(fuel)}} id = "petrol" value = "2" type = "radio" className = "radio" name = "fuel" /> <span>Бензин</span>
                  </div>
                  <div className = "rowRadio">
                    <input onChange = {(evt) => {setFuel(evt.target.value); console.log(fuel)}}  id = "diesel" value = "1" type = "radio" className = "radio" name = "fuel" /> <span>Дизель</span>
                  </div>
                  <div className = "rowRadio">
                    <input onChange = {(evt) => {setFuel(evt.target.value); console.log(fuel)}}  id = "electro" value = "3" type = "radio" className = "radio" name = "fuel" /> <span>Электро</span>
                  </div>
                </div>
                <div className = "miniFilterColumn">
                  <span className = "graySpan">Объем куб. см. </span>
                  <input onChange = {(evt) => {setVolume(evt.target.value)}}  className = "textCalculator" />
                </div>
                  {/*
                  <div className = "miniFilterColumn">
                    <span className = "graySpan">Мощность л.с</span>
                    <input onChange = {(evt) => {setFuel(evt.target.value)}}  className = "textCalculator" />
                  </div>
                  */}
                  <div style = {{marginTop: "30px"}} className = "radioButtons">
                    <span className = "graySpan">Возраст машины</span>
                      <div className = "rowRadio">
                        <input onChange = {(evt) => {setAge(evt.target.value); console.log(age)}} id = "before3" value = {`2`} type = "radio" className = "radio" name = "agy" /> <span>до 3 лет</span>
                      </div>
                      <div className = "rowRadio">
                        <input onChange = {(evt) => {setAge(evt.target.value); console.log(age)}}  id = "after3before5" value = {`4`} type = "radio" className = "radio" name = "agy" /> <span>от 3 до 5 лет</span>
                      </div>
                      <div className = "rowRadio">
                        <input onChange = {(evt) => {setAge(evt.target.value); console.log(age)}}  id = "after5before7" value = {`6`} type = "radio" className = "radio" name = "agy" /> <span>от 5 до 7 лет</span>
                      </div>
                      <div className = "rowRadio">
                        <input onChange = {(evt) => {setAge(evt.target.value); console.log(age)}}  id = "after7" value = {`8`} type = "radio" className = "radio" name = "agy" /> <span>более 7 лет</span>
                      </div>
                  </div>
                <div style = {{marginTop: "30px"}} className = "radioButtons">
                  <span className = "graySpan">Получатель</span>
                  <div className = "rowRadio">
                    <input onChange = {(evt) => {setWho(evt.target.value); console.log(who)}} value = {'fiz'} type = "radio" className = "radio" name = "who" /> <span>Физ. лицо</span>
                  </div>
                  <div className = "rowRadio">
                    <input onChange = {(evt) => {setWho(evt.target.value); console.log(who)}} value = {`ur`} type = "radio" className = "radio" name = "who" /> <span>Юр. лицо</span>
                  </div>
                </div>
              </form>
                <div className = "calculatorInfo gray">
                  <ReCAPTCHA onChange = {getResultCaptcha} sitekey = "6LfH24IjAAAAAO9eK2ohFsSFvWDO9ZeajsDDNHqg" />
                  <button onClick = {getResultCalc} className = "typicalButton"> Расчитать </button>
                </div>
            </div>

            <div className = "twoColumn">

              <div className ={`column ${(!fromIs) ? 'japan' : 'korea'}`}>
                <span className = "gray" style = {{color: "#242424", fontSize: "16px"}}>Средняя стоимость автомобиля со всеми расходами::</span>

                  <div className = "resultPriceBlock">
                    {
                      (calcData.row) ?
                                  <table key = "TABLERESULT">
                                      <tr>
                                        <td>Стоимость в {(fromIs == 'japan') ? 'Японии' : 'Корее'}</td>
                                        <td>USD</td>
                                        <td>{(fromIs == 'japan') ? 'JPY' : 'KPW'}</td>
                                        <td>RUB</td>
                                      </tr>
                                      <tr>
                                        <td>Цена на аукционе</td>
                                        <td>{``}</td>
                                        <td>{calcData.row[0].tag1} {(fromIs == 'japan') ? '¥' : '₩'}</td>
                                        <td>{``}</td>
                                      </tr>
                                      <tr>
                                        <td>Расходы по {(fromIs == 'japan') ? 'Японии' : 'Корее'}, Фрахт</td>
                                        <td>{``}</td>
                                        <td>{(fromIs == 'japan') ? calcData.row[3].tag1 : calcData.row[3].tag1} {(fromIs == 'japan') ? '¥' : '₩'}</td>
                                        <td>{``}</td>
                                      </tr>
                                      <tr>
                                        <td>{(fromIs == 'japan') ? '' :''}</td>
                                        <td>{``}</td>
                                        <td>{(fromIs == 'japan') ? '' : '' }{(fromIs == 'japan') ? '' : ''}</td>
                                        <td>{``}</td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>{``}</td>
                                        <td>{''} {(fromIs == 'japan') ? '' : ''}</td>
                                        <td>{``}</td>
                                      </tr>
                                      <tr>
                                        <td>Стоимость в России</td>
                                        <td>USD</td>
                                        <td>{(fromIs == 'japan') ? 'JPY' : 'KPW'}</td>
                                        <td>RUB</td>
                                      </tr>
                                      <tr>
                                        <td>Услуги по таможенному оформлению, СВХ, СБКТС</td>
                                        <td>{``}</td>
                                        <td>{``}</td>
                                        <td>{(fromIs == 'japan') ? calcData.row[6].tag3 : calcData.row[6].tag3} ₽</td>
                                      </tr>
                                      <tr>
                                        <td>Комиссия компании</td>
                                        <td>{``}</td>
                                        <td>{``}</td>
                                        <td>{(fromIs == 'japan') ? calcData.row[7].tag3 : calcData.row[7].tag3} ₽</td>
                                      </tr>
                                      <tr>
                                        <td>Таможенная пошлина и Утилизационный сбор </td>
                                        <td>{``}</td>
                                        <td>{``}</td>
                                        <td>{(who == 'fiz') ? parseFloat(calcData.info.fiz * usdR).toFixed(0) : parseFloat(calcData.info.jur * usdR).toFixed(0)} ₽</td>
                                      </tr>
                                  </table>
                                 :
                                 ''
                    }
                    <h2 style = {{marginTop: "40px", fontFamily: "Oswald",}}ref={total}>Итоговая сумма: {(usdR && calcData.sum) ? parseFloat(calcData.sum*usdR).toFixed(0) + ' ₽': 'Укажите все данные'}</h2>
                  </div>
              </div>
              <div className = "calculatorInfo gray">
                <button onClick = {() => {
                  dispatch(setSum(parseFloat(calcData.sum*usdR).toFixed(0)))
                  dispatch(showPop(true));
                }} className = "typicalButton"> Хочу такую же </button>
              </div>

            </div>
          </article>

        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Calculator;
