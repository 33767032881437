import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {showPop,setDataForms} from '../../redux/actions';

import {filter} from '../../workers/workers';

import Gallery from '../info/Gallery';

const OneResult = ({activeIndex, clicker, indexer, car, fullInfo}) => {

  const page = useSelector( state => state.router.page );

  const dispatch = useDispatch();

  const toggler = (element) => {
    element.classList.toggle('bigPictureResult');
  }

  useEffect(() => {
    console.log(fullInfo)
  },[activeIndex,clicker,fullInfo])

  switch (page) {

    case 'auctions':
      if(window.innerWidth > 798) {
        return(
            <div className={`buyedCar ${(indexer === activeIndex) ? "bigResult" : false }`} >
              <div className="infoColumn">
                {
                   (indexer === activeIndex && fullInfo[0]) ? <Gallery domain = {false} subClass = {'resultGallery'} dir = "hor" imgs = {fullInfo[0]} /> : <img onClick = {(indexer !== activeIndex) ? (evt) => clicker(evt,indexer,car.ID) : (evt) => clicker(evt,-1,car.ID)} src = {`${car.IMAGES}`} />
                }
              </div>
              <div className={` infoColumn ${ (indexer === activeIndex) ? 'openResult' : ''}`}>
                <h5 className = "carName">{car.MARKA_NAME + " " + car.MODEL_NAME + " " + car.YEAR}</h5>
                <span className = "lotNumber">ЛОТ № {car.LOT}</span>
                <span className = "buyDate">{car.AUCTION_DATE}</span>
              </div>
              <div className="infoColumn">
                <div className = "infoRow">
                  <span className = "graySpan">Кузов</span> <span><strong>{car.KUZOV}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Объем</span> <span><strong>{car.ENG_V}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Пробег</span> <span><strong>{car.MILEAGE}</strong></span>
                </div>
                {
                   (indexer === activeIndex && fullInfo[0]) ?
                        (<div className = "infoRow">
                          <span className = "graySpan">Цвет</span> <span><strong>{car.COLOR}</strong></span>
                         </div>)
                      : ''
                }
              </div>
              <div className="infoColumn">
                  <div className = "infoRow">
                    <span className = "graySpan">Год</span> <span><strong>{car.YEAR}</strong></span>
                  </div>
                  <div className = "infoRow">
                    <span className = "graySpan">КПП</span> <span><strong>{car.KPP}</strong></span>
                  </div>
                  <div className = "infoRow">
                    <span className = "graySpan">Оценка</span> <span><strong>{car.RATE}</strong></span>
                  </div>
                  {
                     (indexer === activeIndex && fullInfo[0]) ?
                          (<div className = "infoRow">
                            <span className = "graySpan">Комплек. </span> <span><strong>{car.GRADE}</strong></span>
                           </div>)
                        : ''
                  }
              </div>
              <div className="infoColumn">
                  <h3 className = "bigPrice"> ~ {car.AVG_PRICE}¥</h3>
                  {/*<button onClick = {() => {dispatch(showPop(true)); dispatch(setDataForms(car));}} className = "typicalButton toSmallButton"> УЗНАТЬ СТОИМОСТЬ </button> */ }
                  {
                    (indexer === activeIndex && fullInfo[0]) ? <button   onClick = {() => {dispatch(showPop(true)); dispatch(setDataForms(car));}} className = "typicalButton toSmallButton"> Хочу эту </button>
                                                             : <button  onClick = {(evt) => clicker(evt,indexer,car.ID)} className = "typicalButton toSmallButton"> Полная информация </button>
                  }
              </div>
            </div>
        )
      } else {
        return(
          <div className="buyedCar Mobile">
            <div className="infoColumn textCar">
              <h5 className = "carName">{car.MARKA_NAME + " " + car.MODEL_NAME + " " + car.YEAR}</h5>
            </div>
            <div className="mobileMiddleInfoCar">
            <div className="infoColumn">
              {
                 (indexer === activeIndex && fullInfo[0]) ? <Gallery domain = {false} subClass = {'resultGallery'} dir = "hor" imgs = {fullInfo[0]} /> : <img src = {`${car.IMAGES}`} />
              }
            </div>
              <div className="infoColumn">
                <div className = "infoRow">
                  <span className = "graySpan">Кузов</span> <span><strong>{car.KUZOV}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Объем</span> <span><strong>{car.ENG_V}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Пробег</span> <span><strong>{car.MILEAGE}</strong></span>
                </div>

                  <div className = "infoRow">
                    <span className = "graySpan">Год</span> <span><strong>{car.YEAR}</strong></span>
                  </div>
                  <div className = "infoRow">
                    <span className = "graySpan">КПП</span> <span><strong>{car.KPP}</strong></span>
                  </div>
                  <div className = "infoRow">
                    <span className = "graySpan">Оценка</span> <span><strong>{car.RATE}</strong></span>
                  </div>
            </div>
            </div>
            <div className="infoColumn">
                <h3 className = "bigPrice"> ~ {car.AVG_PRICE}¥</h3>
                  <button  onClick = {(evt) => clicker(evt,indexer,car.ID)} className = "typicalButton toSmallButton"> Полная информация </button>
            </div>
          </div>
        )
      }
      case 'statistic':
      if(window.innerWidth > 798) {
        return(
          <div className="buyedCar">
            <div className="infoColumn">
              {
                 (indexer === activeIndex && fullInfo[0]) ? <Gallery domain = {false} subClass = {'resultGallery'} dir = "hor" imgs = {fullInfo[0]} /> : <img src = {`${car.IMAGES}`} />
              }
            </div>
            <div className="infoColumn">
              <h5 className = "carName">{car.MARKA_NAME + " " + car.MODEL_NAME + " " + car.YEAR}</h5>
              <span className = "lotNumber">ЛОТ № {car.LOT}</span>
              <span className = "buyDate">{car.AUCTION_DATE}</span>
            </div>
            <div className="infoColumn">
              <div className = "infoRow">
                <span className = "graySpan">Кузов</span> <span><strong>{car.KUZOV}</strong></span>
              </div>
              <div className = "infoRow">
                <span className = "graySpan">Объем</span> <span><strong>{car.ENG_V}</strong></span>
              </div>
              <div className = "infoRow">
                <span className = "graySpan">Пробег</span> <span><strong>{car.MILEAGE}</strong></span>
              </div>
            </div>
            <div className="infoColumn">
                <div className = "infoRow">
                  <span className = "graySpan">Год</span> <span><strong>{car.YEAR}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">КПП</span> <span><strong>{car.KPP}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Оценка</span> <span><strong>{car.RATE}</strong></span>
                </div>
            </div>
            <div className="infoColumn">
                <h3 className = "bigPrice"> ~ {car.AVG_PRICE}¥</h3>
            </div>
          </div>
        )
      }else{
        return(
          <div className="buyedCar Mobile">
            <div className="infoColumn textCar">
              <h5 className = "carName">{car.MARKA_NAME + " " + car.MODEL_NAME + " " + car.YEAR}</h5>
              <span className = "buyDate">{car.AUCTION_DATE}</span>
              <span className = "lotNumber">ЛОТ № {car.LOT}</span>
            </div>
            <div className="mobileMiddleInfoCar">
            <div className="infoColumn">
              {
                 (indexer === activeIndex && fullInfo[0]) ? <Gallery domain = {false} subClass = {'resultGallery'} dir = "hor" imgs = {fullInfo[0]} /> : <img src = {`${car.IMAGES}`} />
              }
            </div>
              <div className="infoColumn">
                <div className = "infoRow">
                  <span className = "graySpan">Кузов</span> <span><strong>{car.KUZOV}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Объем</span> <span><strong>{car.ENG_V}</strong></span>
                </div>
                <div className = "infoRow">
                  <span className = "graySpan">Пробег</span> <span><strong>{car.MILEAGE}</strong></span>
                </div>

                  <div className = "infoRow">
                    <span className = "graySpan">Год</span> <span><strong>{car.YEAR}</strong></span>
                  </div>
                  <div className = "infoRow">
                    <span className = "graySpan">КПП</span> <span><strong>{car.KPP}</strong></span>
                  </div>
                  <div className = "infoRow">
                    <span className = "graySpan">Оценка</span> <span><strong>{car.RATE}</strong></span>
                  </div>
            </div>
            </div>
            <div className="infoColumn">
                <h3 className = "bigPrice">  ~ {car.AVG_PRICE}¥</h3>
            </div>
          </div>
        )
      }
    default:
      return (<h4> Перестаньте уже находить баги :) </h4>)
  }
}

export default OneResult;
