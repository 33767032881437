//Шапка
export const CREATE_MENU = 'MAINHEADER/CREATE_MENU';
export const SHOW_FORM = 'MAINHEADER/SHOW_FORM';

//Управление отображением страниц
export const PAGE = 'ROUTER/ROUTEPAGE';

//Управление отображением видео
export const FETCH_VIDEOS = 'YOUTUBE/FETCH_VIDEOS';
export const FETCH_VIDEOS_ALL = 'YOUTUBE/FETCH_VIDEOS_ALL';
export const VIDEO_LOADER = 'YOUTUBE/VIDEO_LOADER';
export const VIDEO_LOADER_ALL = 'YOUTUBE/VIDEO_LOADER_ALL';

//Управление отображением новостей
export const FETCH_NEWS = 'NEWS/FETCH_NEWS';
export const NEWS_LOADER = 'NEWS/NEWS_LOADER';
export const BIG_NEWS = 'NEWS/BIG_NEWS';

//Данные
export const FETCH_BUYEDCARS = 'DATA/BUYEDCARS'; //Купленные машины

//Аукционы
export const MODELS = 'AUC/MODELS';
export const MARK = 'AUC/MARK';
export const YEAR = 'AUC/YEAR';
export const BODY = 'AUC/BODY';
export const VOLUME = 'AUC/VOLUME';
export const COLOR = 'AUC/COLOR';
export const RESULTCARS = 'AUC/RESULTCARS';
export const RESULTCARSSTAT = 'AUC/RESULTCARSSTAT';
export const CHECK_BUTTONS_DAYS = 'AUC/CHECK_BUTTONS_DAYS';

//Корейские машины
export const FETCH_ADDKOREACARS = 'KOREA/ADDKOREA';
export const FETCH_GETKOREACARS = 'KOREA/GETKOREA';

//Подвал

//Доставка
export const BODYCAR = 'DELIVERY/BODYCAR';

//Мобила
export const ONNER = 'MOBILE/ONNER';
export const TOGGLEBURGER = 'HEADER/TOGGLEBURGER';

//DOM События
export const SCROLLER = 'WINDOW/SCROLLER';

//Авторизация
export const CHECKLOGIN = 'ADMIN/CHECKLOGIN';
export const AUTHKEY = 'ADMIN/AUTHKEY';

//Формы
export const SHOW_POP = 'FORM/SHOW_POP';
export const PARAMSCAR = 'FORM/PARAMS';
export const NAMEFORM = 'FORM/NAME';
export const TELFORM = 'FORM/TEL';
export const CITYFORM = 'FORM/CITY';
export const ALLSUM = 'FORM/SUM';

//Корейские машины
export const MANAGERS = 'ADMIN/MANAGERS';
