import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchVideos, videoLoader, allVideoLoader, fetchVideosAll} from '../../redux/actions';

import stylesVideo from '../../css/videos.css';

const YouTube = () => {
  const dispatch = useDispatch();
  const api = useSelector(state => state.info.youtubeAPI);
  const videoList = useSelector(state => state.info.videos);
  const allVideoList = useSelector(state => state.info.allVideos);
  const playlist = useSelector(state => state.info.idPlaylist);
  const loadingReviews =  useSelector(state => state.info.videoLoader);
  const loadingAll =  useSelector(state => state.info.allVideoLoader);

  const page = useSelector(state => state.router.page);

  const url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResult=50&playlistId=${playlist}&key=${api}`;
  const urlAllVideo = `https://www.googleapis.com/youtube/v3/search?key=${api}&channelId=UC_25tidd_GvRK7lF3dOc-kA&part=snippet,id&order=date&maxResults=20`;
  const videoUrl = `https://www.youtube.com/watch?v=`;

  if(!loadingReviews) {
    dispatch(videoLoader(true));
    dispatch(fetchVideos(url));
    console.log("Loading Reviews: " + loadingReviews)
  }
  if(!loadingAll) {
    dispatch(allVideoLoader(true));
    dispatch(fetchVideosAll(urlAllVideo));
    console.log("Loading AllChannel: " + loadingAll)

  }
  switch(page) {
    case 'main':
      return(
        <section className="YouTube">
          {
            (videoList[0]) ?
                            videoList[0].map( (item, index ) => {
                              if(index<3) {
                              return (
                                <article  key = {`youtubekeys_${index}_${item}`} className="oneVideo">
                                <a  className = "linkVideo" href = {`${videoUrl}${item.snippet.resourceId.videoId}`} target="_blank">
                                  <div className="remoty"><img  className = "imgVideo" src = {item.snippet.thumbnails.high.url} /></div>
                                  <span className = "nameCategoryVideo">Видео-отзывы</span>
                                  <h4 className = "titleVideo" key = {`keyVideo_${item.id}`}> {item.snippet.title} </h4>
                                </a>
                                </article>
                              )
                              }
                            })
                           :
                           <h3> Загрузка идет дольше обычного...хм </h3>
          }
        </section>
      )
    case 'videos':
      return(
        <section className="BigYouTube YouTubeAll">
          {
            (allVideoList[0]) ?
                            allVideoList[0].map( (item, index ) => {
                              console.log(item)
                              return (
                                <article key = {`${item.id}_key_${index}`} className="oneVideo">
                                <a  className = "linkVideo" href = {`${videoUrl}${item.id.videoId}`} target="_blank">
                                  <div className="remoty"><img  className = "imgVideo" src = {item.snippet.thumbnails.high.url} /></div>
                                  <span className = "nameCategoryVideo">Видео-отзывы</span>
                                  <h4 className = "titleVideo" key = {`keyVideo_${item.id}`}> {item.snippet.title} </h4>
                                </a>
                                </article>
                              )
                            })
                           :
                           <h3> Загрузка идет дольше обычного...хм </h3>
          }
        </section>
      )
    case 'reviews':
      return(
        <section className="BigYouTube Reviews">
          {
            (videoList[0]) ?
                            videoList[0].map( (item, index ) => {
                              console.log(item)
                              return (
                                <article key = {`${item.id}_key_${index}`} className="oneVideo">
                                <a  className = "linkVideo" href = {`${videoUrl}${item.snippet.resourceId.videoId}`} target="_blank">
                                  <div className="remoty"><img  className = "imgVideo" src = {item.snippet.thumbnails.high.url} /></div>
                                  <span className = "nameCategoryVideo">Видео-отзывы</span>
                                  <h4 className = "titleVideo" key = {`keyVideo_${item.id}`}> {item.snippet.title} </h4>
                                </a>
                                </article>
                              )
                            })
                           :
                           <h3> Загрузка идет дольше обычного...хм </h3>
          }
        </section>
      )
    default:
      return(
        <section className="YouTube">
          <h2>Увы, YouTube не подключен к данной странице :(</h2>
        </section>
      )
  }

}

export default YouTube;
