import React , {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCookies} from 'react-cookie';
import {setLogin, setAuthKey} from '../../redux/actions';

import Helmet from 'react-helmet';

import ReCAPTCHA from 'react-google-recaptcha';

import GodMod from './GodMod';

import adminstyles from './adminstyles.css';

const AdminPanel = ({}) => {

  const dispatch = useDispatch();

  const checkLogin = useSelector(state => state.controller.checklogin);
  const authKey = useSelector(state => state.controller.authKey);
  const secretKey = useSelector(state => state.controller.secretKey);
  const domain = useSelector(state => state.controller.domain);

  const testuser = {username: 'test', password: 'test'};

  const [adminLogin, setAdminLogin] = useState('');
  const [adminPass, setAdminPass] = useState('');
  const [resultCaptcha, setResultCaptcha] = useState(false);
  const [adminCookie, setAdminCookie] = useCookies(['admin']);

  const auth = async (evt) => {
    if(!resultCaptcha) {
      evt.target.innerText = 'Введите капчу';
      setTimeout(() => {evt.target.innerText = "Войти";},1000);
    }
    const authForm = new FormData();
          authForm.append('auth','true');
          authForm.append('username',adminLogin);
          authForm.append('password',adminPass);
          authForm.append('secretKey',secretKey);
    const request = fetch(domain+"/engine/admin/auth.php", {
      method: 'POST',
      body: authForm,
    })
    .then(response => response.json())
    .then(data => {
      if(data.result) {
        dispatch(setLogin(adminLogin));
        setAdminCookie('Name',adminLogin,{
          path: '/admin',
          maxAge: 15000,
        });
        setAdminCookie('Password',adminPass,{
          path: '/admin',
          maxAge: 15000,
        });
        setAdminCookie('authkey',data.authkey,{
          path: '/admin',
          maxAge: 15000,
        });
      } else {
        evt.target.innerText = data['message'];
        setTimeout(() => {evt.target.innerText = "Войти";},1000);
      }
      console.log(data)
    })
    .catch(err => console.log('Ошибка авторизации'))

  }

  const getResultCaptcha = (value) => {
    console.log(value);
    setResultCaptcha(value);
  }

  useEffect(() => {
    if(adminCookie.Name && adminCookie.Password) {
      dispatch(setLogin(adminCookie.Name));
    }
  },[])
  useEffect(() => { },[resultCaptcha])

  if(!checkLogin) {
    return (
      <>
      <Helmet>
        <title>Автозаказ/ADMINPANEL</title>
        <meta charSet = "utf-8" />
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        <meta name = "viewport" content = "width=device-width" />
        <meta name = "description" content = "Автозаказ - Автомобили из Японии и Кореи!" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Автозаказ - Автомобили из Японии и Кореи" />
        <meta name="twitter:description" content="Автозаказ - Автомобили из Японии и Кореи!" />
        <meta name="twitter:image" content="http://example.com/logo.png" />
      </Helmet>
      <section className="adminLoginContainer">
        <div className = "adminLogin">
          <input onChange={(evt) => setAdminLogin(evt.target.value)} value = {adminLogin} type="text" className = "adminInput" placeholder = "Введите логин" />
          <input onChange={(evt) => setAdminPass(evt.target.value)} value = {adminPass} type="text" className = "adminInput" placeholder = "Введите пароль" />
          <ReCAPTCHA onChange = {getResultCaptcha} sitekey = "6LfH24IjAAAAAO9eK2ohFsSFvWDO9ZeajsDDNHqg" />
          <button onClick={(evt) => auth(evt)}>Войти {adminCookie.Name}</button>
        </div>
      </section>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Автозаказ/ADMINPANEL</title>
          <meta charSet = "utf-8" />
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
          <meta name = "viewport" content = "width=device-width" />
          <meta name = "description" content = "Автозаказ - Автомобили из Японии и Кореи!" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Автозаказ - Автомобили из Японии и Кореи" />
          <meta name="twitter:description" content="Автозаказ - Автомобили из Японии и Кореи!" />
          <meta name="twitter:image" content="http://example.com/logo.png" />
        </Helmet>
        <GodMod />
      </>
    )
  }


}

export default AdminPanel;
