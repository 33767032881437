import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {scroller} from './redux/actions';

import {Helmet} from "react-helmet";

import Header from './components/header/MainHeader'; //Импортирует шапку

import MainPop from './components/pops/mainPop';

import Router from './components/main/Router'; //Импортирует роутер который контроллирует отображение страниц

import Footer from './components/footer/Footer'; //Импортирует роутер который контроллирует отображение страниц

import {fetchBuyedCars} from './redux/actions';

let stopFetchKorea = false;
let stopFetchCars = false;

function App({page}) {

  const dispatch = useDispatch();
  const scrollnow = useSelector(state => state.controller.scroll);
  const buyedCars = useSelector(state => state.data.buyedCars);
  const secretKey = useSelector(state => state.controller.secretKey);
  const linkgetcars = useSelector(state => state.controller.getCarsApi);

  const showPopNow = useSelector(state => state.shower.showPop);

  const handleScroll = () => {
    const pos = window.pageYOffset;
    dispatch(scroller(pos));
  }

  if(!buyedCars[0] && !stopFetchCars) {
    dispatch(fetchBuyedCars(linkgetcars,secretKey));
    stopFetchCars = true;
  }

  useEffect(() => {
    window.addEventListener('scroll',handleScroll, {passive: true});
    //handleScroll();
    //console.log(scrollnow)
    return () => {
      window.removeEventListener('scroll',handleScroll);
    }
  },[scrollnow])

  return (
    <div className="App">
      <Helmet>
        <title>Автозаказ - Автомобили из Японии и Кореи</title>
        <meta charSet = "utf-8" />
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        <meta name = "viewport" content = "width=device-width" />
        <meta name = "description" content = "Автозаказ - Автомобили из Японии и Кореи!" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Автозаказ - Автомобили из Японии и Кореи" />
        <meta name="twitter:description" content="Автозаказ - Автомобили из Японии и Кореи!" />
        <meta name="twitter:image" content="http://example.com/logo.png" />
      </Helmet>

      {
        (showPopNow) ?
                    <MainPop namepop = 'simplepop' />
                  : false
      }

      <Header />

      <Router page2 = {page} />

      <Footer />

    </div>
  );
}


export default App;
