/**
*Перенес в Workers работу с API и Обработку инпутов. (реализация)
*/
import React , {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import {router} from '../../redux/actions';

import {fetchBuyedCars} from '../../redux/actions';
import {fetchKoreaCars} from '../../redux/actions';

import {handleInput} from '../../workers/domworkers';
import {fetchAddCar} from '../../workers/apiworkers';
import {fetchAdd} from '../../workers/apiworkers';
import {fetchKoreaCar} from '../../workers/apiworkers';
import {fetchAPI} from '../../workers/apiworkers';
import {fetchDelete} from '../../workers/apiworkers';

import adminstyles from './adminstyles.css';
import headerLogo from '../../includes/imgs/logo.svg';


const GodMod = ({}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const managers = useSelector(state => state.info.managers);

  const checkLogin = useSelector(state => state.controller.checklogin);
  const typesContent =useSelector(state => state.data.typesAdminContent);
  const addCarApi = useSelector(state => state.controller.addCarApi);
  const addKoreaApi = useSelector(state => state.controller.addKoreaApi);
  const secretKey = useSelector(state => state.controller.secretKey);
  const buyedCars  = useSelector(state => state.data.buyedCars);
  const url = useSelector(state => state.controller.getManagersApi);
  const setManagerApi = useSelector(state => state.controller.setManagerApi);
  const deleteApi = useSelector(state => state.controller.deleteApi);
  const updManagerApi = useSelector(state => state.controller.updateManagerApi);
  const koreaCars = useSelector(state => state.data.stopperKorea);
  const linkgetcars = useSelector(state => state.controller.getCarsApi);
  const linkgetcarskorea = useSelector(state => state.controller.getCarsKorea);


  const [textButton,setTextButton] = useState('Добавить');
  const [editTextButton,setEditTextButton] = useState('Редактировать');
  const [managersAdmin,setManagersAdmin] = useState([{}]);

  const [stopFetchKorea,setStopFetchKorea] = useState(false);
  const [stopFetchCars,setStopFetchCars] = useState(false);
  const [stopGetAdmin,setStopGetAdmin] = useState(false);

  const nameMan = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
  const loginMan = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
  const passMan = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
  const telMan = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

  const jwt = useSelector(state => state.controller.jwt);

  const [adminLogin, setAdminLogin] = useState('');
  const [adminPass, setAdminPass] = useState('');
  const [selectTypeContent, setSelectTypeContent] = useState(0);
  //state добавленной машины купленной
  const [addCar, setAddCar] = useState({
    namemain: ' ',
    lot: ' ',
    date: ' ',
    body: ' ',
    volume: ' ',
    milage: ' ',
    age: ' ',
    kpp: ' ',
    review: ' ',
    pricevdk: ' ',
    pricejapan: ' ',
  });
  const [addManager, setAddManager] = useState({
  });
  //state добавленной машины купленной корея
  const [addKorea, setAddKorea] = useState({
  });
  const [tempInfo,setTempInfo] = useState('');
  //state добавленной машины купленной
  const [delivCity, setDelivCity] = useState({
  });
  const inputImg = useRef(null);
  const inputKoreaImg = useRef(null);

  if(!stopFetchCars) {
    setTimeout(() => {dispatch(fetchBuyedCars(linkgetcars,secretKey));},2000)
    console.log('Купленные машины: ');
    setStopFetchCars(true);
  }
  if(!stopFetchKorea) {
    setTimeout(() => {dispatch(fetchKoreaCars(linkgetcarskorea,secretKey));},2000)
    setStopFetchKorea(true);
    console.log('Корея машины: ');
  }
  const editText = () => {
    setTextButton('Добавление...');
    setTimeout(() => {
      setTextButton('Добавить');
    },1000)
  }
  const editManager = (evt,index) => {
    console.log(index)
    console.log(loginMan.current[index].current);
    let login = loginMan.current[index].current.name;
    let name = nameMan.current[index].current.name
    let pass = passMan.current[index].current.name
    let tel = telMan.current[index].current.name

    fetchAPI(evt,secretKey,updManagerApi, {
     'updateManger': 'true',
     [login] : loginMan.current[index].current.value,
     [name]: nameMan.current[index].current.value,
     [pass]: passMan.current[index].current.value,
     [tel] : telMan.current[index].current.value,
    })

    setStopGetAdmin(false);
    //console.log(nameMan.current[index].current.value);
    //console.log(passMan.current[index].current.value);
    //console.log(telMan.current[index].current.value);
  }

  useEffect(() => {
    if(!stopGetAdmin) {
      let result;
        setTimeout(() => { fetch(url).then(res => res.json()).then(data => setManagersAdmin(data)) },1400);
      }
      setStopGetAdmin(true);
  },[])
 useEffect(() => {},managersAdmin)
  const goTo = (stringPage,evt) => {
    evt.preventDefault();
    navigate("/")
    dispatch(router(stringPage));
    console.log(stringPage);
  }

  const deleteCar = (evt,id,tablename) => {
    fetchDelete(evt,secretKey,deleteApi, {
     'deleteCar': 'true',
     'tablename': tablename,
     'id': id,
    })
    dispatch(fetchBuyedCars(linkgetcars,secretKey));
    dispatch(fetchKoreaCars(linkgetcarskorea,secretKey));
    setStopFetchCars(false);
    setStopFetchKorea(false);
  }
  const handleInput = (setter,evt, img = false, tempSetter, temp,ref = false) => {  //Перенести в Workers
    let tempCar = temp;
    if(!img) {
      if(evt.target.name !== 'volume') {
            setter(evt.target.value);
            tempCar[evt.target.name] = evt.target.value;
            tempSetter(tempCar);
      } else {
          setter(evt.target.value);
          tempCar[evt.target.name] = evt.target.value;
          tempSetter(tempCar);
      }
    } else {
      //let tempImg = new File(URL.createObjectURL(evt.target.files[0]),`${evt.target.name}.png`,{type:tempImg.type});
      let bloby = evt.target.files[0];
      console.log(evt.target.files[0])
          bloby = new File([bloby],`${evt.target.files[0].name}.png`,{type:"image/*"});
          tempCar[evt.target.name] = bloby;
          tempSetter(tempCar);
    }
    console.log(addCar);
  }

  const adminContent = [ //Контент для удобства
    (<div className="mainPanel">
        <h2>Система управления сайтом предоставленна INSIDE360</h2>
        <h2>Место для возможной статистики по сайту отображения диаграм и любой другой полезной информации</h2>
        <h3>Для добавленния нового функционала обращайтесь в INSIDE360! </h3>
        <h3>Сделанно с любовью! :3 </h3>
    </div>),
    (<div key = "buyeddiv1" className="buyedPanel">
      <div key = "buyeddiv2"  className="addCarContainer">
        <span>Название</span>
        <input key = "name" name = 'namemain' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.namemain} type="text" className = "adminInput" placeholder = "Название машины" />
        <span>Номер лота</span>
        <input key = "lot" name = 'lot' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.lot} type="text" className = "adminInput" placeholder = "Номер лота (не обязательно)" />
        <span>Дата покупки</span>
        <input key = "date" name = 'date' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.date} type="date" className = "adminInput" placeholder = "Дата покупки" />
        <span>Кузов</span>
        <input key = "body" name = 'body' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.body} type="text" className = "adminInput" placeholder = "Кузов" />
        <span>Объем</span>
        <input key = "volume" name = 'volume' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.volume} type="text" className = "adminInput" placeholder = "Объем" />
        <span>Пробег</span>
        <input key = "milage" name = 'milage' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.milage} type="text" className = "adminInput" placeholder = "Пробег" />
        <span>Год</span>
        <input key = "age" name = 'age' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.age} type="text" className = "adminInput" placeholder = "Год" />
        <span>КПП</span>
        <input key = "kpp" name = 'kpp' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.kpp} type="text" className = "adminInput" placeholder = "КПП (не обязательно)" />
        <span>Оценка</span>
        <input key = "review" name = 'review' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.review} type="text" className = "adminInput" placeholder = "Оценка (не обязательно)" />
        <span>Цена Владивосток</span>
        <input key = "pricevdk" name = 'pricevdk' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.pricevdk} type="text" className = "adminInput" placeholder = "Цена Владивосток" />
        <span>Цена в Японии </span>
        <input key = "pricejapan" name = 'pricejapan' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddCar, addCar)} value = {addCar.pricejapan} type="text" className = "adminInput" placeholder = "Цена в Японии (не обязательно)" />
        <span>Картинка</span>
        <input key = "img" name = 'img' onChange={(evt) => handleInput(setTempInfo,evt,true, setAddCar, addCar)} type="file" className = "adminInput"/>
        <button  key = "buyed" onClick={(evt) => {fetchAddCar(evt, secretKey, addCarApi, setAddCar, addCar); console.log(addCar); editText(); setStopFetchCars(false)}} className = "addCarButton">{textButton}</button>
      </div>
      <div className = "downBuyedContainer">
      <h1 className="downTitle">Все машины</h1>
      {
        (buyedCars[0]) ?
                        buyedCars.map( (car, index) => {
                          return(
                            <article className = "buyedList buyedCarsAdmin">
                            <div className="buyedCar">
                              <div className="infoColumn">
                                <img src = {`${car.imglink}`} />
                              </div>
                              <div className="infoColumn textCar">
                                <h5 className = "namecar">{car.namecar}</h5>
                                <span className = "lotNumber">ЛОТ № {car.lotnumber}</span>
                                <span className = "buyDate">{car.date}</span>
                              </div>
                                <button onClick = {evt => deleteCar(evt, car.id, 'buyedcars')} className = "deleteCar">Удалить</button>
                            </div>

                          </article>
                          )
                        })
                      : <h4> Проданных машин еще нет :( </h4>
      }
      </div>
    </div>),
    (<div key = "koreadiv"  className="buyedPanel">
      <div  key = "koreadiv2"  className="addCarContainer">
        <input key = "name22" name = 'name2' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddKorea, addKorea)} value = {addKorea.name2} type="text" className = "adminInput" placeholder = "Название машины" />
        <input key = "price22" name = 'price2' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddKorea, addKorea)} value = {addKorea.price2} type="text" className = "adminInput" placeholder = "Цена" />
        <input key = "img22" name = 'img2' onChange={(evt) => handleInput(setTempInfo,evt,true, setAddKorea, addKorea)} type="file" className = "adminInput"/>
        <button key = "koreabutton" onClick={(evt) => {fetchAddCar(evt, secretKey, addKoreaApi, setAddKorea, addKorea); editText()}} className = "addCarButton">{textButton}</button>
      </div>
      <div className = "downBuyedContainer">
      <h1 className="downTitle">Все машины</h1>
      {
        (koreaCars[0]) ?
                        koreaCars.map( (car, index) => {
                          return(
                            <article className = "buyedList buyedCarsAdmin">
                            <div className="buyedCar">
                              <div className="infoColumn">
                                <img src = {`${car.imglink}`} />
                              </div>
                              <div className="infoColumn textCar">
                                <h5 className = "namecar">{car.name}</h5>
                                <span className = "lotNumber">{car.price}</span>
                              </div>
                              <button onClick = {evt => deleteCar(evt, car.id, 'koreaauto')} className = "deleteCar">Удалить</button>
                            </div>
                          </article>
                          )
                        })
                      : <h4> Проданных машин еще нет :( </h4>
      }
      </div>
    </div>),
    (<div className="mainPanel"> {/* Страница калькулятора
      <input key = "city" name = 'city' onChange={(evt) => handleInput(setTempInfo,evt, false, setDelivCity, delivCity)} value = {delivCity.name} type="text" className = "adminInput" placeholder = "Город" />
      <input key = "milage" name = 'milage' onChange={(evt) => handleInput(setTempInfo,evt, false, setDelivCity, delivCity)} value = {delivCity.pricevdk} type="text" className = "adminInput" placeholder = "Расстояние" />
      <input key = "time" name = 'time' onChange={(evt) => handleInput(setTempInfo,evt, false, setDelivCity, delivCity)} value = {delivCity.pricevdk} type="text" className = "adminInput" placeholder = "Время пути" />
      <button onClick={(evt) => {fetchAddCar(evt, secretKey, addCarApi, setAddCar, addCar); editText()}} className = "addCarButton">{textButton}</button>
       */}
    </div>),
    (<div className="buyedPanel">
      <div className="addCarContainer">
      <h1 className="downTitle">Добавить менеджера</h1>
        <input key = "manname" name = 'manname' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddManager, addManager)} value = {addManager.name} type="text" className = "adminInput" placeholder = "Имя менеджера" />
        <input key = "manuser" name = 'manuser' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddManager, addManager)} value = {addManager.login} type="text" className = "adminInput" placeholder = "Имя пользователя ( Латиницей )" />
        <input key = "manpass" name = 'manpass' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddManager, addManager)} value = {addManager.password} type="text" className = "adminInput" placeholder = "Пароль" />
        <input key = "manpass2" name = 'manpass2' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddManager, addManager)} value = {addManager.pass2} type="text" className = "adminInput" placeholder = "Повторить пароль" />
        <input key = "mantel" name = 'mantel' onChange={(evt) => handleInput(setTempInfo,evt, false, setAddManager, addManager)} value = {addManager.tel} type="text" className = "adminInput" placeholder = "Телефон" />
        <button onClick={(evt) => {fetchAdd(evt, secretKey, setManagerApi, setAddManager, addManager); editText();}} className = "addCarButton">{textButton}</button>
      </div>

      <div className = "downBuyedContainer columnsy">
          <h1 className="downTitle">Все контакты</h1>
          {
            (managersAdmin[0])?
              managersAdmin.map( (item,index) => {
                console.log(item)
                return(
                  <div key = {`manager_key_`+item.id} style = {{marginTop: '30px', marginBottom: '30px;', backgroundColor: "rgba(0,0,0,0.3)", padding: "5px"}}>
                    <h3 style = {{marginBottom: '30px;'}}>Менеджер: {item.name}</h3>
                    <div className = "tableNamed">
                      <span>Логин (не менять)</span>
                      <span>Пароль </span>
                      <span>Имя</span>
                      <span>Телефон</span>
                    </div>
                    <input ref ={loginMan.current[index]} name = "login" placeholder={item.login} defaultValue={item.login}/>
                    <input ref ={passMan.current[index]} name = "password" placeholder={item.password} defaultValue={item.password}/>
                    <input ref ={nameMan.current[index]} name = "name" placeholder={item.name} defaultValue={item.name}/>
                    <input ref ={telMan.current[index]} name = "tel" placeholder={item.tel} defaultValue={item.tel}/>
                    <button onClick = {(evt) => {editManager(evt,index)}}>{editTextButton}</button>
                    <button onClick = {(evt) => {
                      deleteCar(evt, item.id,'adminusers');
                      let temp = [...managersAdmin];
                          temp.splice(index,1);
                          setManagersAdmin(temp);
                    }}>Удалить</button>
                  </div>
                )
              } )
                         : false
          }
      </div>
    </div>)
  ]
  //РЕНДЕР ТУТ !
  if(!checkLogin) {
    return (
      <section className="adminPanelContainer">
        <h4>У Вас нет доступа к админ-панели!</h4>
      </section>
    )
  } else {
    return (
      <section className = "adminPanelContainer">
        <article className = "leftSide">
        <img className = {`${'logo'} admin`} src = {`${headerLogo}`} />
          {
            typesContent.map( (item,index) => {
              return <div onClick={() => {setSelectTypeContent(item.id); console.log('2222')}} key = {`adminitemkey_${item.title}_${index}`} className="adminMenuItem"><span>{item.title}</span></div>
            })
          }
          <div onClick={(evt) => goTo('main',evt)} className="adminMenuItem"><span>Назад к сайту</span></div>
        </article>
        <article className = "rightSide">
          {
            typesContent.map( (item,index) => (index === selectTypeContent) ? <div  key = {`adminContentkey_${index}`}><h1 className="titleAdmin">{item.title}</h1>{adminContent[index]}</div> : '' )
          }

        </article>
      </section>
    )
  }

}

export default GodMod;
