import React from 'react';
import ReactDOM from 'react-dom/client';

import { compose, createStore , applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import thunk from 'redux-thunk';

import { rootReducer } from './redux/rootReducer';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from 'react-router-dom';

import App from './App';
import Admin from './components/adminpanel/Admin';
import Error from './Error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App page = {'main'}/>,
    errorElement: <Error/>,
  },
  {
    path: "/auctions",
    element:  <App page = {'auctions'} />,
    errorElement: <Error/>,
  },
  {
    path: "/koreaauto",
    element:  <App page = {'koreaauto'} />,
    errorElement: <Error/>,
  },
  {
    path: "/statistic",
    element:  <App page = {'statistic'} />,
    errorElement: <Error/>,
  },
  {
    path: "/calculators",
    element:  <App page = {'calculators'} />,
    errorElement: <Error/>,
  },
  {
    path: "/videos",
    element:  <App page = {'videos'} />,
    errorElement: <Error/>,
  },
  {
    path: "/reviews",
    element:  <App page = {'reviews'} />,
    errorElement: <Error/>,
  },
  {
    path: "/blogs",
    element:  <App page = {'blogs'} />,
    errorElement: <Error/>,
  },
  {
    path: "/bignews",
    element:  <App page = {'bignews'} />,
    errorElement: <Error/>,
  },
  {
    path: "/delivery",
    element:  <App page = {'delivery'} />,
    errorElement: <Error/>,
  },
  {
    path: "/contacts",
    element:  <App page = {'contacts'} />,
    errorElement: <Error/>,
  },
  {
    path: "/publicoffert",
    element:  <App page = {'publicoffert'} />,
    errorElement: <Error/>,
  },
  {
    path: "/political",
    element:  <App page = {'political'} />,
    errorElement: <Error/>,
  },
  {
    path: "/Admin",
    element:  <Admin />,
    errorElement: <Error/>,
  }
])

const store = createStore(rootReducer, compose(
  applyMiddleware(
    thunk
  ),
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
));

const app = (
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <RouterProvider router = {router} />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
)

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(app);
