import React from 'react';
import {PAGE} from './types';

const initialState = {
  page: 'main',
}

export const routReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE:
      return {...state, page: action.payload}
    default:
      return state;
  }
}
