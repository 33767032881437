import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import stylesDelivery from '../../css/delivery.css';

import CalculatorDelivery from '../info/CalculatorDelivery'

const Delivery = () => {

  const page = useSelector(state => state.router.page);

  const resultQuntity = 6;
  switch (page) {
    case 'delivery':
      return(
        <section className="blogContainer">
          <h2 className = "titlePage">Доставка в регионы</h2>
          <CalculatorDelivery />
        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Delivery;
