import React , {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import {sendMail} from '../../workers/apiworkers'
import {setNameForm , setTelForm , setCityForm, showPop} from '../../redux/actions';

const PopUp = ({namepop,objectArgs = {}},country = 'japan') => {

  const name = useSelector(state => state.data.nameForm);
  const tel = useSelector(state => state.data.telForm);
  const mailurl = useSelector(state => state.controller.mailer);
  const skey = useSelector(state => state.controller.secretKey);
  const paramsCarsToSend = useSelector(state => state.data.paramsCarsToSend);
  const allSum = useSelector(state => state.data.allSum);

  const [textButton, setTextButton] = useState("Отправить");
  const [myCountry, setMyCountry] = useState('');

  const dispatch = useDispatch();

  const checkPhone = (string) => {
    let match = string.match(/(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?/g)
    return match;
  }

  const sender = () => {
    let check = checkPhone(tel);
    if(!name) {
      setTextButton('Пустое имя!');
      setTimeout(() => {
        setTextButton('Отправить');
      },2500)
      return;
    }
    console.log(check)
      if(!check) {
        setTextButton('Проверьте телефон!');
        setTimeout(() => {
          setTextButton('Отправить');
        },2500)
        return;
      } else {
        sendMail(mailurl,skey,[name,tel],paramsCarsToSend,myCountry,allSum);
        setTextButton('Ваша заявка отправлена!');
      }
  }
useEffect(() => {},[myCountry,allSum])
  switch (namepop) {

    case 'simplepop':
      return(
        <div className = "popupContainer">
        {
        (textButton == 'Отправить' || textButton == 'Проверьте телефон!' || textButton == 'Пустое имя!') ?
          <section className = "popup">
            <div onClick={()=>{dispatch(showPop(false))}} className = "exitPop">
              <span></span>
              <span></span>
            </div>
            <h2>Оставьте ваши данные и мы свяжемся с вами в ближайшее время</h2>

            <input onClick={()=>{}} onChange={(evt)=>{dispatch(setNameForm(evt.target.value))}}  value = {name} type="text" name="namePop" placeholder="ИМЯ" />
            <input onClick={()=>{}} onChange={(evt)=>{dispatch(setTelForm(evt.target.value))}} value = {tel} type="text" name="telPop" placeholder="ТЕЛЕФОН" />
            <div className = {`radioContainer`}>
              <span className = "graySpan">Страна вывоза</span>
              <div className = "rowBlock"><input onChange={(item) => setMyCountry(item.target.value)} type = "radio" name = "country" value = "japan" /><p>Япония</p></div>
              <div className = "rowBlock"><input onChange={(item) => setMyCountry(item.target.value)} type = "radio" name = "country" value = "korea" /><p>Корея</p></div>
            </div>
            <button onClick={()=>{sender()}} className = "simpleButton">{textButton}</button>
            <p> Нажимая на кнопку «Отправить» вы соглашаетесь с <a target="_blank" href = "/political"> политикой конфиденциальности </a> </p>

          </section>
          :  <section className = "popup">
              <div onClick={()=>{dispatch(showPop(false))}} className = "exitPop">
                      <span></span>
                      <span></span>
                    </div>
                    <h2 key = "ThankYou2">Спасибо за заявку!</h2>
                    <h2 key = "ThankYou2">Вы в шаге от машины Вашей мечты!</h2>
             </section>
          }
        </div>
      )
      break;

    default:
      return(
        <div className = "popupContainer">
          <section className = "popup">
            <div onClick={()=>{dispatch(showPop(false))}} className = "exitPop">
              <span></span>
              <span></span>
            </div>
            <h2>Оставьте ваши данные и мы свяжемся с вами в ближайшее время</h2>
            <input onClick={()=>{}} onChange={(evt)=>{dispatch(setNameForm(evt.target.value))}}  value = {name} type="text" name="namePop" placeholder="ИМЯ" />
            <input onClick={()=>{}} onChange={(evt)=>{dispatch(setTelForm(evt.target.value))}} value = {tel} type="text" name="namePop" placeholder="ТЕЛЕФОН" />

            <div className = {`radioContainer`}>
              <span className = "graySpan">Страна вывоза</span>
              <div className = "rowBlock"><input onChange={(item) => setMyCountry(item.target.value)} type = "radio" name = "country" value = "japan" /><p>Япония</p></div>
              <div className = "rowBlock"><input onChange={(item) => setMyCountry(item.target.value)} type = "radio" name = "country" value = "korea" /><p>Корея</p></div>
            </div>
            <button onClick={()=>{}} className = "simpleButton">ОТПРАВИТЬ</button>
            <p> Нажимая на кнопку «Отправить» вы соглашаетесь с <a target="_blank" href = "/political"> политикой конфиденциальности </a> </p>
          </section>
        </div>
      )
  }

}

export default PopUp;
