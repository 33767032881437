import {combineReducers} from 'redux';
import {postReducer} from './postReducer'; //Тестовый редьюсер на посты
import {menuReducer} from './menuReducer'; //Меню шапки
import {showerReducer} from './showerReducer'; //Редьюсер для чека отображения внутри элементов
import {routReducer} from './routReducer'; //Редьюесер для чека страницу
import {infoReducer} from './infoReducer'; //Статичная инфа
import {dataReducer} from './dataReducer'; //Данные
import {aucReducer} from './aucReducer'; //Для аукционов
import {controllerReducer} from './controllReducer'; //Редьюсер для контроля состояний разных элементов

export const rootReducer = combineReducers({
  menu: menuReducer,
  posts: postReducer,
  shower: showerReducer,
  router: routReducer,
  info: infoReducer,
  data: dataReducer,
  auctions: aucReducer,
  controller: controllerReducer,
})
