import React from 'react';
import {SHOW_FORM, SHOW_POP} from './types';

const initialState = {
  showHeaderForm: false,
  showPop: false,
}

export const showerReducer = (state = initialState, action) => {

  switch (action.type) {
    case SHOW_FORM:
      return {...state, showHeaderForm: action.payload }
    case SHOW_POP:
      return {...state, showPop: action.payload }
    default:
      return state;

  }
}
