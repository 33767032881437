import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setNameForm, setTelForm} from '../../redux/actions';

const FormCallback = () => {
  const nameForm = useSelector(state => state.data.nameForm);
  const telForm = useSelector(state => state.data.telForm);

  const dispatch = useDispatch();

  return(
    <form className = "formInHeader" >
      <input type="text" onChange={(evt) => dispatch(setNameForm(evt.target.value))} value = {nameForm} placeholder = "Как Вас зовут?" className = "inputInHeader" />
      <input type="text" onChange={(evt) => dispatch(setTelForm(evt.target.value))} value = {telForm}  placeholder = "Ваш номер телефона?" className = "inputInHeader"  />
    </form>
  )
}


export default FormCallback;
