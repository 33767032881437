import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import wPhoneIcone from '../../includes/imgs/white_phone_icon.svg';
import wTg from '../../includes/imgs/white_tg_icon.svg';

import {router,togglerBurger} from '../../redux/actions';

const MobileBurger = () => {

  const burgerCss = 'MobileBurger';

  const menu = useSelector(state => state.menu.headerMenu);
  const scrollnow = useSelector(state => state.controller.scroll);

  const toggleBurger = useSelector(state => state.menu.toggleBurger);

  const dispatch = useDispatch();

  const goTo = (stringPage) => {
    dispatch(router(stringPage));
    dispatch(togglerBurger('hiddenBurger'));
    console.log(stringPage);
  }

  const toggleBurgerAction = () => {
    if(toggleBurger !== "showBurger") {
      dispatch(togglerBurger('showBurger'));
    } else {
      dispatch(togglerBurger('hiddenBurger'));
    }
  }

  return(
    <div className = {`${burgerCss} `}>
      <div onClick={toggleBurgerAction} className = {`burgerWrapper ${toggleBurger}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav className = {`${'headerNav'} ${toggleBurger} ${(scrollnow > 300)?'fixedHeaderBurger':'' }`}>
        <ul>
        {
          menu.map( (menu, index) => {
            return <li key={index+"_HeaderNav_"+menu.title}><a className = "headerNavLinks" onClick = { evt => goTo(menu.link)} href = {`/${menu.link}`}>{menu.title}</a></li>
          })
        }
        </ul>
      </nav>

    </div>
  )
}


export default MobileBurger;
