import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setActiveButtonDays} from '../../redux/actions';
import {getAllInfo} from '../../workers/apiworkers';

import OneResult from './oneResult';

const ResultAuction = () => {

  const dispatch = useDispatch();

  const page = useSelector(state => state.router.page);
  const active = useSelector(state => state.controller.activeDay)
  const cars = useSelector(state => state.auctions.cars); //Получать будем из API
  const carsStat = useSelector(state => state.auctions.carsStat); //Получать будем из API
  const selectDate = useSelector(state => state.auctions.selectDate);
  const secretKey = useSelector(state => state.controller.secretKey);
  const domain = useSelector(state => state.controller.domain);

  const [filters, setFilters] = useState([]);
  const [aucFilters, setAucFilters] = useState([]);
  const [aucDate,setAucDate] = useState(['','','']);

  const [activeResult, setActiveResult] = useState(-1);
  const [bigReuslt, setBigReuslt] = useState({});

 function sorty(type) {
   let temp = [...carsStat];
       console.log(temp.sort( (a, b) => a[type] > b[type] ? 1: -1));
       temp.sort( (a, b) => a[type] > b[type] ? 1: -1)
       setFilters(temp);
 }
 const getAllDate = () => {
   let currentDate = new Date()
   let day = currentDate.getDate()
   let month = currentDate.getMonth() + 1
   let year = currentDate.getFullYear()
   setAucDate([`${day}-${month}-${year}`,`${day+1}-${month}-${year}`,`${day+2}-${month}-${year}`])
 }
  function toFull(evt,index,id) {
    if(index===activeResult){
       setActiveResult(-1);
       return index;
     }
    setActiveResult(index);
    setBigReuslt({});
    getAllInfo(evt,secretKey, id, domain,setBigReuslt);
  }
  useEffect(() => {
    if(!aucDate[0]) getAllDate();
  },[])
  useEffect(() => {console.log(bigReuslt)},[bigReuslt])
  useEffect(() => {setActiveResult(-1)},[cars,carsStat,filters])
  switch(page) {
      case 'auctions':
        return(
          <section className = "resultAuctionContainer">
            <div className = "buttonsSelectDays">
              <button onClick = {() => {
                setAucFilters([...cars].sort( (a, b) => a.AUCTION_DATE >= aucDate[0] ? 1: -1));
                dispatch(setActiveButtonDays(['activeDay',false,false]))
              }} className = {`${active[0]} daysButton`}>Сегодня {`${aucDate[0]}`}</button>
              <button onClick = {() => {
                setAucFilters([...cars].sort( (a, b) => a.AUCTION_DATE >= aucDate[1] ? 1: -1));
                dispatch(setActiveButtonDays([false,'activeDay',false]))
              }} className = {`${active[1]} daysButton`}>Завтра {`${aucDate[1]}`}</button>
              <button onClick = {() => {
                setAucFilters([...cars].sort( (a, b) => a.AUCTION_DATE >= aucDate[2] ? 1: -1));
                dispatch(setActiveButtonDays([false,false,'activeDay']))
              }} className = {`${active[2]} daysButton`}>Послезавтра {`${aucDate[2]}`}</button>
            </div>

            <section className = "allResults">
              {
                (aucFilters[0] && aucFilters[0].YEAR) ?
                              aucFilters.map( (car, index) => {
                                if(car.AVG_PRICE > 0) {
                                  return <OneResult activeIndex = {activeResult} clicker = {toFull} fullInfo = {bigReuslt} indexer = {index} car = {car}  key = {`auction_keys_${index}`}/>
                                } else {
                                  return
                                }
                              })
                            :
                              cars.map( (car, index) => {
                                if(car.AVG_PRICE > 0) {
                                  return <OneResult activeIndex = {activeResult} clicker = {toFull} fullInfo = {bigReuslt} indexer = {index} car = {car}  key = {`auction_keys_${index}`}/>
                                } else {
                                  return
                                }
                              })
              }
            </section>
          </section>
        )
        case 'statistic':
          return(
            <section className = "resultAuctionContainer">
              <div className = "filtersButtons">
                <span>Сортировка: </span>
                <button onClick = {() => {setFilters([...carsStat].sort( (a, b) => a.AVG_PRICE > b.AVG_PRICE ? 1: -1));} } className = {`${active[0]} filtersButton`}>Стоимость</button>
                <button onClick = {() => {setFilters([...carsStat].sort( (a, b) => a.YEAR > b.YEAR ? 1: -1));}} className = {`${active[1]} filtersButton`}>Год</button>
                <button onClick = {() => {setFilters([...carsStat].sort( (a, b) => a.MILAGE > b.MILAGE ? 1: -1));}} className = {`${active[2]} filtersButton`}>Пробег</button>
                <button onClick = {() => {setFilters([...carsStat].sort( (a, b) => a.AUCTION_DATE > b.AUCTION_DATE ? 1: -1));}} className = {`${active[2]} filtersButton`}>Дата аукциона</button>
              </div>

              <section className = "allResults">
              {
                (filters[0] && filters[0].YEAR) ?
                              filters.map( (car, index) => {
                                if(car.AVG_PRICE > 0) {
                                  return <OneResult activeIndex = {activeResult} clicker = {toFull} fullInfo = {bigReuslt} indexer = {index} car = {car}  key = {`auction_keys_${index}`}/>
                                } else {
                                  return
                                }
                              })
                            :
                              carsStat.map( (car, index) => {
                                if(car.AVG_PRICE > 0) {
                                  return <OneResult activeIndex = {activeResult} clicker = {toFull} fullInfo = {bigReuslt} indexer = {index} car = {car}  key = {`auction_keys_${index}`}/>
                                } else {
                                  return
                                }
                              })
              }
              </section>
            </section>
          )
      default:
        return(
          <h3></h3>
        )
  }

}

export default ResultAuction;
