import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import stylesBuyedCar from '../../css/buyedCars.css';

import {setDataForms, showPop} from '../../redux/actions';

import Gallery from './Gallery';

const BuyedCars = () => {

  const page = useSelector(state => state.router.page);
  const carsList = useSelector(state => state.data.buyedCars);
  const domain = useSelector(state => state.controller.domain);
  const paramsCarsToSend = useSelector(state => state.data.paramsCarsToSend);

  const dispatch = useDispatch();

  const sender = (car) => {
    dispatch(showPop(true));
    dispatch(setDataForms(car));
  }

  switch(page) {

    case 'main':
      if(window.innerWidth > 798) {
        return(
          <section className="buyedCarsContainer">
            <h2 className="">Авто в наличии</h2>
            <p>
              Ниже представлены автомобили, купленные на аукционах Японии без посредников под полную пошлину с доставкой в Россию.
              Цены указаны со всеми расходами и доставкой во Владивосток.
            </p>
            <p>
              В процессе покупки все машины предварительно отбираются менеджерами и согласуются с клиентом.
              Аукционный лист на каждый лот обязательно переводится специалистом-переводчиком в Японии.
            </p>
            <p>
              Клиенты заказывают авто со всей страны с доставкой в свой город. Отправка авто по России осуществляется транспортными компаниями.
              Самый популярный способ доставки по суше – автовозом. Стоимость и срок зависят от удаленности города.
            </p>
            <article className = "buyedList">
              {
                (carsList[0]) ?
                                carsList.map( (car, index) => {
                                  return(
                                    <div onClick = {(evt) => sender(car)} key = {`oneBuyedCars_${index}_${car.date}`} className="buyedCar">
                                      <div className="infoColumn">
                                        <Gallery dir = "hor" imgs = {car.imglink} />
                                      </div>
                                      <div className="infoColumn textCar">
                                        <h5 className = "carName">{car.namecar}</h5>
                                        <span className = "lotNumber">ЛОТ № {car.lotnumber}</span>
                                        <span className = "buyDate">{car.date}</span>
                                      </div>
                                      <div className="infoColumn">
                                        <div className = "infoRow">
                                          <span className = "graySpan">Кузов</span> <span><strong>{car.bodycar}</strong></span>
                                        </div>
                                        <div className = "infoRow">
                                          <span className = "graySpan">Объем</span> <span><strong>{car.volume}</strong></span>
                                        </div>
                                        <div className = "infoRow">
                                          <span className = "graySpan">Пробег</span> <span><strong>{car.mileage}</strong></span>
                                        </div>
                                      </div>
                                      <div className="infoColumn">
                                          <div className = "infoRow">
                                            <span className = "graySpan">Год</span> <span><strong>{car.year}</strong></span>
                                          </div>
                                          <div className = "infoRow">
                                            <span className = "graySpan">КПП</span> <span><strong>{car.kpp}</strong></span>
                                          </div>
                                          <div className = "infoRow">
                                            <span className = "graySpan">Оценка</span> <span><strong>{car.review}</strong></span>
                                          </div>
                                      </div>
                                      <div className="infoColumn">
                                          <span className = "graySpan">Цена во Владивостоке</span>
                                          <h3 className = "bigPrice">{car.pricevdk} ₽</h3>
                                          {
                                            /*
                                              <span className = "graySpan priceJapan">Цена в Японии</span>
                                              <h4 className = "smallPrice">{car.pricejapan} ¥</h4>
                                            */
                                          }
                                      </div>
                                    </div>
                                  )
                                })
                              : <h4> Проданных машин еще нет :( </h4>
              }
            </article>
          </section>
        )
      } else {
        return(
          <section className="buyedCarsContainer">
            <h2 className="">Авто в наличии</h2>
            <p>
              Ниже представлены автомобили, купленные на аукционах Японии без посредников под полную пошлину с доставкой в Россию.
              Цены указаны со всеми расходами и доставкой во Владивосток.
            </p>
            <p>
              В процессе покупки все машины предварительно отбираются менеджерами и согласуются с клиентом.
              Аукционный лист на каждый лот обязательно переводится специалистом-переводчиком в Японии.
            </p>
            <p>
              Клиенты заказывают авто со всей страны с доставкой в свой город. Отправка авто по России осуществляется транспортными компаниями.
              Самый популярный способ доставки по суше – автовозом. Стоимость и срок зависят от удаленности города.
            </p>
            <article className = "buyedList">
              {
                (carsList[0]) ?
                                carsList.map( (car, index) => {
                                  return(
                                    <div onClick = {(evt) => sender(car)} key = {`buyedCarsOne{index}_${car.id}`} className="buyedCar Mobile">
                                      <div className="infoColumn textCar">
                                        <h5 className = "carName">{car.namecar}</h5>
                                        <span className = "buyDate">{car.date}</span>
                                        <span className = "lotNumber">ЛОТ № {car.lotnumber}</span>
                                      </div>
                                      <div className="mobileMiddleInfoCar">
                                      <div className="infoColumn">
                                        <Gallery domain = {domain}  dir = "hor" imgs = {car.imglink} />
                                        <img src = {`${car.imglink}`} />
                                      </div>
                                        <div className="infoColumn">
                                          <div className = "infoRow">
                                            <span className = "graySpan">Кузов</span> <span><strong>{car.bodycar}</strong></span>
                                          </div>
                                          <div className = "infoRow">
                                            <span className = "graySpan">Объем</span> <span><strong>{car.volume}</strong></span>
                                          </div>
                                          <div className = "infoRow">
                                            <span className = "graySpan">Пробег</span> <span><strong>{car.mileage}</strong></span>
                                          </div>

                                            <div className = "infoRow">
                                              <span className = "graySpan">Год</span> <span><strong>{car.year}</strong></span>
                                            </div>
                                            <div className = "infoRow">
                                              <span className = "graySpan">КПП</span> <span><strong>{car.kpp}</strong></span>
                                            </div>
                                            <div className = "infoRow">
                                              <span className = "graySpan">Оценка</span> <span><strong>{car.review}</strong></span>
                                            </div>
                                      </div>
                                      </div>
                                      <div className="infoColumn">
                                          <span className = "graySpan">Цена во Владивостоке</span>
                                          <h3 className = "bigPrice">{car.pricevdk} ₽</h3>
                                          <span className = "graySpan priceJapan">Цена в Японии</span>
                                          <h4 className = "smallPrice">{car.pricejapan} ¥</h4>
                                      </div>
                                    </div>
                                  )
                                })
                              : <h4> Проданных машин еще нет :( </h4>
              }
            </article>
          </section>
        )
      }
    default:
      return (
        <h4>Очень странное расположение элемента. Кажется его не должно тут быть о-о</h4>
      )
  }

}

export default BuyedCars;
