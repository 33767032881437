import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {router} from '../../redux/actions';

const HotMenu = () => {

  const dispatch = useDispatch();

  const page = useSelector(state => state.router.page);

  switch(page) {
    case 'main':
      return(
        <section className="hotMenuContainer">
          <button className = "hotAuction" onClick={()=>{dispatch(router('auctions'))}}>АУКЦИОН ОНЛАЙН</button>
          <button className = "hotStatistic" onClick={()=>{dispatch(router('statistic'))}}>СТАТИСТИКА</button>
          <button className = "hotKorea" onClick={()=>{dispatch(router('koreaauto'))}}>КОРЕЙСКИЕ АВТО</button>
        </section>
      )
    default:
      return(
        <h4>Кнопка тут не нужна :( </h4>
      )
  }

}

export default HotMenu;
