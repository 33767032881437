import {CREATE_MENU, TOGGLEBURGER} from './types';

const initialMenu = {
  headerMenu: [
    {
      title: 'Аукционы Японии',
      link: 'auctions',
    },
    {
      title: 'Корейские авто',
      link: 'koreaauto',
    },
    {
      title: 'Статистика',
      link: 'statistic',
    },
    {
      title: 'Калькуляторы',
      link: 'calculators',
    },
    {
      title: 'Наш канал',
      link: 'videos',
    },
    {
      title: 'Отзывы',
      link: 'reviews',
    },
    {
      title: 'Блог',
      link: 'blogs',
    },
    /*
    {
      title: 'Доставка в регионы',
      link: 'delivery',
    },
    */
    {
      title: 'Контакты',
      link: 'contacts',
    },
],
toggleBurger: 'hiddenBurger',
}


export const menuReducer = (state = initialMenu, action) => {

  switch (action.type) {
    case CREATE_MENU:
      return { ...state, headerMenu: [...state.menu, action.payload] }
    case TOGGLEBURGER:
      return { ...state, toggleBurger: action.payload}
    default: return state;
  }

}
