import {CHECK_BUTTONS_DAYS,ONNER, SCROLLER, CHECKLOGIN, AUTHKEY} from './types';
const domain = 'https://testinside360.ru';
const initialState = {
  activeDay: [false,false,false],
  onner: false,
  scroll: 0,
  checklogin: false,
  domain: 'https://testinside360.ru',
  authKey: '',
  addCarApi: domain+'/engine/addcar/index.php',
  addCarApi: domain+'/engine/addcar/index.php',
  getNewsApi: domain+'/engine/getNews/index.php',
  mailer: domain+'/engine/mailer/mail.php',
  aucAPI: '',
  getMarks: ``,
  getCarsApi: domain+'/engine/getcars/index.php',
  setManagerApi: domain+'/engine/getmanagers/index.php',
  updateManagerApi: domain+'/engine/updateManager/index.php',
  deleteApi: domain+'/engine/Delete/index.php',
  calcApi: domain+'/engine/getcalc/index.php',
  getManagersApi: domain+'/engine/getmanagers/index.php?getmanagers=true',
  addKoreaApi: domain+'/engine/addkorea/index.php',
  getCarsKorea: domain+'/engine/getkorea/index.php',
  secretKey: 'sQ@Vr8oSCIRbzh0TC8za3o#xRkkL6@cplOuK',
}

export const controllerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_BUTTONS_DAYS:
      return {...state, activeDay: action.payload}
    case ONNER:
      return {...state, onner: action.payload}
    case SCROLLER:
      return {...state, scroll: action.payload}
    case CHECKLOGIN:
      return {...state, checklogin: action.payload}
    case AUTHKEY:
      return {...state, authKey: action.payload}
    default: return state;
  }
}
