import React from 'react';

import {MODELS, MARK, YEAR, BODY, COLOR, VOLUME, RESULTCARS, RESULTCARSSTAT} from './types';

const initialState = {
  mark: [''],
  model: [''],
  year: ['1996','1997','1998','1999','2000','2001','2002','2003','2004','2005','2007','2008','2009','2010','2011','2012','2013','2015','2016','2017','2018','2019','2020','2021','2022'],
  body: [''],
  volume: [''],
  color: [''],
  millagearray: ['10000','30000','45000','70000','100000','125000','150000','200000'],
  review: ['0','1','2','3','4','5'],
  cars: [],
  carsStat: [],
}

export const aucReducer = (state = initialState, action) => {
  switch (action.type) {
    case MARK:
      return {...state, mark: action.payload}
    case MODELS:
      return {...state, model: action.payload}
    case YEAR:
      return {...state, year: action.payload}
    case BODY:
      return {...state, body: action.payload}
    case COLOR:
      return {...state, color: action.payload}
    case VOLUME:
      return {...state, volume: action.payload}
    case RESULTCARS:
      return {...state, cars: action.payload}
    case RESULTCARSSTAT:
      return {...state, carsStat: action.payload}
    default: return state;
  }
}
