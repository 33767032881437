import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Filters from '../auction/Filters'; //Фильтры поиска
import ResultAuction from '../auction/ResultAuction'; //Результаты аукциона

import stylesYouTube from '../../css/video.css';

import YouTube from '../info/YouTube';

const Videos = () => {

  const page = useSelector(state => state.router.page);

  const resultQuntity = 6;
  switch (page) {
    case 'reviews':
      return(
        <section className="videoContainer">
          <h2 className = "titlePage">Отзывы клиентов</h2>
          <YouTube />
        </section>
      )
    case 'videos':
      return(
        <section className="videoContainer">
          <h2 className = "titlePage">Наш YouTube канал</h2>
          <YouTube />
        </section>
      )
    default:
      return(<h4>BAD</h4>)
  }
}

export default Videos;
