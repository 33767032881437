import {CREATE_MENU} from './types';
import {SHOW_FORM} from './types';
import {PAGE} from './types';
import {FETCH_VIDEOS} from './types';
import {FETCH_VIDEOS_ALL} from './types';
import {VIDEO_LOADER} from './types';
import {VIDEO_LOADER_ALL} from './types';
import {FETCH_NEWS} from './types';
import {NEWS_LOADER} from './types';
import {BIG_NEWS} from './types';
import {FETCH_BUYEDCARS} from './types';
import {FETCH_GETKOREACARS, FETCH_ADDKOREACARS} from './types';
import {CHECK_BUTTONS_DAYS} from './types';
import {BODYCAR} from './types';
import {TOGGLEBURGER} from './types';
import {CHECKLOGIN} from './types';
import {AUTHKEY} from './types';

import  {MODELS} from './types';
import  {MARK} from './types';
import  {YEAR} from './types';
import  {BODY} from './types';
import  {COLOR} from './types';
import {VOLUME} from './types';
import {RESULTCARS} from './types';
import {RESULTCARSSTAT} from './types';

import  {SHOW_POP} from './types';
import  {NAMEFORM} from './types';
import {TELFORM} from './types';
import {CITYFORM} from './types';
import {PARAMSCAR} from './types';

import {SCROLLER} from './types';

import {ALLSUM} from './types';

const domain = 'https://testinside360.ru'
//Телефон ПОП
/**
  @arg0 string = string name
*/
export const setNameForm = (name) => {
  return {
    type: NAMEFORM,
    payload: name,
  }
}

/**
  @arg0 string = string name
*/
export const setSum = (sum) => {
  return {
    type: ALLSUM,
    payload: sum,
  }
}
//ИМЯ ПОП
/**
  @arg0 string = string tel
*/
export const setTelForm = (name) => {
  return {
    type: TELFORM,
    payload: name,
  }
}
export const setDataForms = (paramObject) => {
  return {
    type: PARAMSCAR,
    payload: paramObject,
  }
}
export const showPop = (name) => {
  return {
    type: SHOW_POP,
    payload: name,
  }
}

//Город ПОП
/**
  @arg0 string = string city
*/
export const setCityForm = (name) => {
  return {
    type: CITYFORM,
    payload: name,
  }
}

//Авторизация
/**
  @arg0 string = string name
*/
export const setLogin = (name) => {
  return {
    type: CHECKLOGIN,
    payload: name,
  }
}
/**
  @arg0 string = authKey
*/
export const setAuthKey = (string) => {
  return {
    type: AUTHKEY,
    payload: string,
  }
}

//События окна
/**
  @arg0 float = Scroll Value
*/
export const scroller = (floatNumber) => {
  return {
    type: SCROLLER,
    payload: floatNumber,
  }
}

//Бургер меню
/**
  @arg0 string = ClassName
*/
export const togglerBurger = (string) => {
  return {
    type: TOGGLEBURGER,
    payload: string,
  }
}

//Создание меню
export const createMenu = (menu) => {
  return {
    type: CREATE_MENU,
    payload: menu,
  }
}

//Работа с формой в шапке
export const showForm = (boolean) => {
  return {
    type: SHOW_FORM,
    payload: boolean,
  }
}
//Установка кузова в доставке
export const setBodycar = (str) => {
  return {
    type: BODYCAR,
    payload: str,
  }
}
//Роутер
export const router = (stringPage) => {
  return {
    type: PAGE,
    payload: stringPage,
  }
}

//Работы с асихнронными данными

//Видео
export const fetchVideos = (url) => {
  return async dispatch => {
    const response = await fetch(url);
    const json = await response.json();
    //Тут сделать еще сохранение объекта который возвращает ютуб
    dispatch({type: FETCH_VIDEOS, payload: json.items });
  }
}
export const fetchVideosAll = (url) => {
  return async dispatch => {
    const response = await fetch(url);
    const json = await response.json();
    //Тут сделать еще сохранение объекта который возвращает ютуб
    dispatch({type: FETCH_VIDEOS_ALL, payload: json.items });
  }
}
//Лоадер для видео ОТЗЫВЫ
export const videoLoader = (boolean) => {
  return {
    type: VIDEO_LOADER,
    payload: boolean
  }
}
//Лоадер для видео ВЕСЬ КАНАЛ
export const allVideoLoader = (boolean) => {
  return {
    type: VIDEO_LOADER_ALL,
    payload: boolean
  }
}
//Новости
export const fetchNews = (url) => {
  return async dispatch => {
    const formData = new FormData();
          formData.append('getNews','news')
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const json = await response.json();
          json.map((item,index) => {
            let strImgs = item.imgs;
                strImgs = strImgs.replaceAll('"','');
                strImgs = strImgs.replaceAll('[','');
                strImgs = strImgs.replaceAll(']','');
                strImgs = strImgs.split(',');
                item.imgs = strImgs;
                console.log("ON PAYLOAD")
                //console.log(strImgs[0]);
          })
    console.log(json)
    dispatch({type: FETCH_NEWS, payload: json})
  }
}
//Установить большую новость
export const setBigNews = (item) => {
  return {
    type: BIG_NEWS,
    payload: item
  }
}
//Лодер для новостей
export const newsLoader = (boolean) => {
  return {
    type: NEWS_LOADER,
    payload: boolean
  }
}
//Купленные машины API ENGINE
export const fetchBuyedCars = (urlAPI,secretKey) => {
  const formdata = new FormData();
        formdata.append('getcars','1');
        formdata.append('secretKey',secretKey);
  return async dispatch => {
    const response = await fetch(urlAPI,{
      method: 'POST',
      body: formdata
    });
    const json = await response.json();
    console.log("Из fetchBuyedCars: ");
    console.log(json);
    dispatch({type: FETCH_BUYEDCARS, payload: json});
  }
}
//Купленные машины KOREA API ENGINE
export const fetchKoreaCars = (urlAPI,secretKey) => {
  const formdata = new FormData();
        formdata.append('getcars','1');
        formdata.append('secretKey',secretKey);
  return async dispatch => {
    const response = await fetch(urlAPI,{
      method: 'POST',
      body: formdata
    });
    const json = await response.json();
    console.log("Из fetchKoreaCars: ");
    console.log(json);
    dispatch({type: FETCH_GETKOREACARS, payload: json});
  }
}

//Место для работы с аукционами
//Кнопки дней
export const setActiveButtonDays = (array) => {
  return {
    type: CHECK_BUTTONS_DAYS,
    payload: array,
  }
}
//машины


/////РАБОТА С API аукционов
//Купленные машины API ENGINE

export const getMarksApi = (urlAPI) => {
  console.log("Из getMarksApi: ");
  console.log(urlAPI)
  const tempArray = [];
  return async dispatch => {
    const response = await fetch(domain+"/engine/getcars/index.php?dataApi='select+marka_name+from+main+group+by+marka_id+order+by+marka_id+ASC+limit+200",{
        method: 'GET',
        });
    const json = await response.json();
    console.log(json);
    for(let mark of json){
      tempArray.push(mark.MARKA_NAME)
    }
    console.log(tempArray)
    dispatch({type: MARK, payload: tempArray});
  }
}

export const getModelApi = (stringModel) => {
  console.log("Из getModelApi: ");
  console.log(stringModel.value)
  const tempArray = [];
  return async dispatch => {
    const response = await fetch(domain+`/engine/getcars/index.php?dataApi=select+model_name+from+main+where+marka_name%3D%27${stringModel.value}%27+group+by+model_id+order+by+model_name`,{
      method: 'GET',
      });
    const json = await response.json();
    for(let mark of json){
      tempArray.push(mark.MODEL_NAME)
    }
    let unique = tempArray.filter((v, i, a) => a.indexOf(v) === i);
    console.log(unique)
    dispatch({type: MODELS, payload: unique});
  }
}
//http://78.46.90.228/xml/xml?code=DvemR43s&sql=select+kuzov+from+main+where+model_name%3D%27allion%27
export const getKuzovApi = (stringModel) => {
  console.log("Из getKuzov: ");
  let link = `/engine/getcars/index.php?dataApi=select+kuzov+from+main+where+model_name%3D%27${stringModel.value}%27`;
  const tempArray = [];
  return async dispatch => {
    const response = await fetch(domain+link,{
      method: 'GET',
      });
    const json = await response.json();
    for(let mark of json){
      tempArray.push(mark.KUZOV)
    }
    let unique = tempArray.filter((v, i, a) => a.indexOf(v) === i);
    console.log(unique)
    dispatch({type: BODY, payload: unique});
  }
}
//http://78.46.90.228/xml/json?code=DvemR43s&sql=select+COLOR+from+main+where+model_name%3D%27corolla%27
export const getColorApi = (stringModel) => {
  console.log("Из getColorApi: ");
  let link = `/engine/getcars/index.php?dataApi=select+COLOR+from+main+where+model_name%3D%27${stringModel.value}%27`;
  const tempArray = [];
  return async dispatch => {
    const response = await fetch(domain+link,{
      method: 'GET',
      });
    const json = await response.json();
    for(let mark of json){
      tempArray.push(mark.COLOR)
    }

    let unique = tempArray.filter((v, i, a) => a.indexOf(v) === i);
    console.log("Цвета: ");
    let upper = unique.map(item => item.toUpperCase());
        unique = upper.filter((v, i, a) => a.indexOf(v) === i);
    console.log(upper);
    dispatch({type: COLOR, payload: unique});
  }
}
///&sql=select+DISTINCT++eng_v+from+main++where+model_name+%3D+%27corolla%27+limit+200
export const getVolumerApi = (stringModel) => {
  console.log("Из getVolumerApi: ");
  let link = `/engine/getcars/index.php?dataApi=select+DISTINCT++eng_v+from+main++where+model_name%3D%27${stringModel.value}%27`;
  const tempArray = [];
  return async dispatch => {
    const response = await fetch(domain+link,{
      method: 'GET',
      });
    const json = await response.json();
    for(let mark of json){
      tempArray.push(mark.ENG_V)
    }
    dispatch({type: VOLUME, payload: tempArray});
  }
}
//sql=select+*+from+main+where+model_name%3D'corolla'+and+marka_name%3D'toyota'+and+avg_price<%3D+1400000+and+eng_v+%3D+1500+and+kuzov+%3D+'kuzov'+and+rate+%3D+4+and+year>%3D1990+limit+100
export const getResultApi = (objectParams) => {
  console.log("Из getResultApi: ");
  let link = `/engine/getcars/index.php?dataApi=select+*+from+main+where+model_name%3D'${objectParams.model}'+and+marka_name%3D'${objectParams.mark}'${(objectParams.price)?"+and+avg_price<%3D+"+objectParams.price:''}${(objectParams.volume)?"+and+eng_v+%3D+"+objectParams.volume:''}${(objectParams.body)?"+and+kuzov+%3D+'"+objectParams.body+"'":''}${(objectParams.review)?"+and+rate+%3D+"+objectParams.review:''}${(objectParams.millage)?"+and+mileage+<%3D+"+objectParams.millage:''}+and+year>%3D${(objectParams.year)?objectParams.year:1996}+limit+100`;
  console.log(link);
  const tempArray = [];
  return async dispatch => {
    dispatch({type: RESULTCARS, payload: []});
    const response = await fetch(domain+link,{
      });
    const json = await response.json();
    for(let item of json){
        tempArray.push(item)
    }
    dispatch({type: RESULTCARS, payload: tempArray});
  }
}
export const getResultStatsApi = (objectParams) => {
  console.log("Из getResultStatsApi: ");
  let link = `/engine/getcars/index.php?statsApi=select+*+from+stats+where+model_name%3D'${objectParams.model}'+and+marka_name%3D'${objectParams.mark}'${(objectParams.price)?"+and+avg_price<%3D+"+objectParams.price:''}${(objectParams.volume)?"+and+eng_v+%3D+"+objectParams.volume:''}${(objectParams.body)?"+and+kuzov+%3D+'"+objectParams.body+"'":''}${(objectParams.review)?"+and+rate+%3D+"+objectParams.review:''}${(objectParams.millage)?"+and+mileage+<%3D+"+objectParams.millage:''}+and+year>%3D${(objectParams.year)?objectParams.year:1996}+limit+100`;
  const tempArray = [];
  return async dispatch => {
    const response = await fetch(domain+link,{
      });
    const json = await response.json();
    for(let item of json){
      tempArray.push(item)
    }
    dispatch({type: RESULTCARSSTAT, payload: tempArray});
  }
}
