export const fetchAddCar = (evt, secretKey, addCarApi,tempSetter, temp) => { //Добаление любых машин Корейских и Японских
  let tempCar = temp;
  const formCar = new FormData();
      for(let key in tempCar) {
        formCar.append(key,tempCar[key]);
        console.log("Значение: GodMod - " + tempCar[key])
      }
       formCar.append('secretKey',secretKey);
       if(tempCar.volume) {
         let floatValue = Number.parseFloat(tempCar.volume);
         if(!Number.isNaN(floatValue)) {
           tempCar.volume = floatValue.toFixed(1);
           tempSetter(tempCar);
         } else {
           tempCar.volume = 'неверные данные';
           tempSetter(tempCar);
         }
      }

  const send = fetch(addCarApi, {
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin':'*'
    },
    method: 'POST',
    body: formCar,
  }).then((response) => {console.log(response.text())}).then((data) => console.log(data)).catch(error => console.log(error));
}

export const fetchAdd = (evt, secretKey, addCarApi,tempSetter, temp) => { //Добаление любых машин Корейских и Японских
  let tempCar = temp;
  const formCar = new FormData();
      for(let key in tempCar) {
        formCar.append(key,tempCar[key]);
        console.log("Значение: FETCH ADD - " + tempCar[key])
      }
       formCar.append('secretKey',secretKey);
       if(tempCar.volume) {
         let floatValue = Number.parseFloat(tempCar.volume);
         if(!Number.isNaN(floatValue)) {
           tempCar.volume = floatValue.toFixed(1);
           tempSetter(tempCar);
         } else {
           tempCar.volume = 'неверные данные';
           tempSetter(tempCar);
         }
      }

  const send = fetch(addCarApi, {
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin':'*'
    },
    method: 'POST',
    body: formCar,
  }).then((response) => {console.log(response.text())}).then((data) => console.log(data)).catch(error => console.log(error));
}

export const getAllInfo = async (evt, secretKey,id, domain,setter) => { //Добаление любых машин Корейских и Японских
    let tempArray = [];
    let linkImgs = `/engine/getcars/index.php?dataApi=sql=select+*+from+main+where+id%3D'${id}'+`;
    const responseImg = await fetch(domain+linkImgs).then(res => res.json()).then(data => {
      //console.log(data);
      let images = data[0].IMAGES;
          images = images.split('#');
          tempArray.push(images)
          setter(tempArray)    //  console.log(tempArray);
    })

}

export const fetchAPI = (evt, secretKey , url , data) => { //Добаление менеджеров
  let tempCar = data;
  const formCar = new FormData();
      for(let key in tempCar) {
        formCar.append(key,tempCar[key]);
        console.log("Значение: FETCH UPDATE - " + tempCar[key])
      }
       formCar.append('secretKey',secretKey);
       if(tempCar.volume) {
         let floatValue = Number.parseFloat(tempCar.volume);
         if(!Number.isNaN(floatValue)) {
           tempCar.volume = floatValue.toFixed(1);
         } else {
           tempCar.volume = 'неверные данные';
         }
      }

  const send = fetch(url, {
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin':'*'
    },
    method: 'POST',
    body: formCar,
  }).then((response) => {console.log(response.text())}).then((data) => console.log(data)).catch(error => console.log(error));
}
export const fetchDelete = (evt, secretKey , url , data) => { //Удаление
  let tempCar = data;
  const formCar = new FormData();
      for(let key in tempCar) {
        formCar.append(key,tempCar[key]);
        console.log("Значение: FETCH UPDATE - " + tempCar[key])
      }
       formCar.append('secretKey',secretKey);
       if(tempCar.volume) {
         let floatValue = Number.parseFloat(tempCar.volume);
         if(!Number.isNaN(floatValue)) {
           tempCar.volume = floatValue.toFixed(1);
         } else {
           tempCar.volume = 'неверные данные';
         }
      }

  const send = fetch(url, {
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin':'*'
    },
    method: 'POST',
    body: formCar,
  }).then((response) => {console.log(response.text())}).then((data) => console.log(data)).catch(error => console.log(error));
}
export const sendMail = (url,secretKey,params,car = {MARKA_NAME: false},country = 'japan',sum) => {
  const formdata = new FormData();
        formdata.append('mailMini','1');
        formdata.append('country',country);
        formdata.append('allsum', sum);
        console.log(sum)
        formdata.append('secretKey',secretKey);
        formdata.append('name',params[0]);
        formdata.append('tel',params[1]);
        if(car.MARKA_NAME) {
        for(let [key,value] of Object.entries(car)) {
            console.log(key);
            console.log(value);
            formdata.append(key,value);
          }
        }
        const send = fetch(url, {
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin':'*'
          },
          method: 'POST',
          body: formdata,
        }).then((response) => {console.log(response.text())}).then((data) => console.log(data)).catch(error => console.log(error));

}
export const fetchKoreaCar = (evt, secretKey, addCarApi,tempSetter, temp) => { //Добаление любых машин Корейских и Японских
  let tempCar = temp;
  const formCar = new FormData();
      for(let key in tempCar) {
        formCar.append(key,tempCar[key]);
        console.log("Значение: GodMod - " + tempCar[key])
      }
       formCar.append('secretKey',secretKey);
       if(tempCar.volume) {
         let floatValue = Number.parseFloat(tempCar.volume);
         if(!Number.isNaN(floatValue)) {
           tempCar.volume = floatValue.toFixed(1);
           tempSetter(tempCar);
         } else {
           tempCar.volume = 'неверные данные';
           tempSetter(tempCar);
         }
      }

  const send = fetch(addCarApi, {
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin':'*'
    },
    method: 'POST',
    body: formCar,
  }).then((response) => {console.log(response.text())}).then((data) => console.log(data)).catch(error => console.log(error));
}


const makeAucQuery = (linkApi,selectstring = '',stringmark = '',year = '1996',) => {
  //select+marka_name+from+main+group+by+marka_id+order+by+marka_id+ASC+limit+200 все марки
  //select+model_name+from+main+where+marka_name%3D'toyota'+group+by+model_id+order+by+model_name //модели привязанные к марке
  let result = [];
  switch(selectstring) {
    case 'getmarks':
      const getresultquery = fetch(`${linkApi}select+marka_name+from+main+group+by+marka_id+order+by+marka_id+ASC+limit+200`).then(response => response.json()).then(result => {

      });
      break;
    case 'getmodels':
      const getresult = fetch(`${linkApi}select+model_name+from+main+where+marka_name%3D'${stringmark}'+group+by+model_id+order+by+model_name`);
      break;
    default:
      return;
  }
  return result;
}
